import { SpinnerCircularFixed } from 'spinners-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header3, Header6, Text4 } from 'styles/typography'
import BackArrow from 'images/BackArrow.svg'
import CodeInput from 'components/CodeInput/CodeInput'

interface WriteGoogleAuthCodeProps {
  loading: boolean
  onChange: (value: string) => void
  onBack: () => void
  error?: string
}

const WriteGoogleAuthCode = ({
  error,
  onBack,
  loading,
  onChange,
}: WriteGoogleAuthCodeProps) => {
  const { t } = useTranslation()

  if (loading) {
    return (
      <StyledLoaderWrapper>
        <SpinnerCircularFixed
          size="48"
          thickness={250}
          color="#ffcd29"
          secondaryColor="rgba(255, 255, 255, 0.1)"
        />
      </StyledLoaderWrapper>
    )
  }

  return (
    <>
      <StyledCaption>{t('qrCode.enterConfirmationCode')}</StyledCaption>
      <StyledWriteDescription>{t('qrCode.enterCode')}</StyledWriteDescription>
      <StyledCodeInputWrapper>
        <CodeInput length={6} onChange={onChange} error={error} />
      </StyledCodeInputWrapper>
      <StyledStep onClick={onBack}>
        <StyledStepText>{t('registation.backButton')}</StyledStepText>
        <StyledStepLogo />
      </StyledStep>
    </>
  )
}

const StyledLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const StyledWriteDescription = styled(Text4)`
  line-height: 125%;
  margin-top: 9px;
  text-align: center;
`

const StyledCodeInputWrapper = styled.div`
  margin-top: 17px;
`

const StyledCaption = styled(Header3)`
  color: #ffcd29;
  text-transform: uppercase;
  line-height: 115%;
`

const StyledStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  width: 100%;
  height: 42px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  margin-top: 46px;
  cursor: pointer;
`

const StyledStepLogo = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${BackArrow});
`

const StyledStepText = styled(Header6)`
  text-transform: uppercase;
`

export default WriteGoogleAuthCode
