import { useStore } from '@nanostores/react'

import { $userProfile } from 'stores/userStore'
import JudgeMyWork from 'components/Judge/Work/JudgeMyWork'
import JudgeWelcome from 'components/Judge/Welcome/JudgeWelcome'

const JudgeWorkPage = () => {
  const user = useStore($userProfile)

  if (user?.role === 'JUDGE') {
    return <JudgeMyWork />
  }

  return <JudgeWelcome />
}

export default JudgeWorkPage
