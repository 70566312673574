import 'components/CodeInput/index.css'

// eslint-disable-next-line import/named
import AuthCode, { AuthCodeRef } from 'react-auth-code-input'
import React, { useEffect, useRef } from 'react'

import { Text6 } from 'styles/typography'
import styled from 'styled-components'

const StyledCodeWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledError = styled(Text6)`
  color: rgb(255, 68, 68);
  align-self: center;
  font-weight: 500;
`

interface CodeInputProps {
  value?: string
  length?: number
  error?: string | null
  disabled?: boolean
  onChange: (value: string) => void
}

const CodeInput = React.forwardRef(
  (
    { error, length, onChange, disabled }: CodeInputProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref
  ) => {
    const AuthInputRef = useRef<AuthCodeRef>(null)
    useEffect(() => {
      if (error) {
        AuthInputRef.current?.clear()
      }
    }, [error])

    return (
      <StyledCodeWrapper>
        <AuthCode
          ref={AuthInputRef}
          onChange={onChange}
          length={length || 4}
          allowedCharacters="numeric"
          containerClassName={'code_block'}
          inputClassName={`code-input ${error ? 'code-input-error' : ''}`}
          disabled={disabled}
        />

        {error && <StyledError>{error}</StyledError>}
      </StyledCodeWrapper>
    )
  }
)

export default CodeInput
