import PhoneInput from 'react-phone-input-2'
import React from 'react'
import styled from 'styled-components'

import 'react-phone-input-2/lib/high-res.css'
import { Text6 } from 'styles/typography'

const StyledLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;

  color: #ffcd29;
`

const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
`

const StyledError = styled(Text6)`
  color: #ff4444;
  position: absolute;
  bottom: 15px;
  left: calc(100% + 11px);
  width: max-content;
  font-weight: 500;
`

interface PhoneNumberInputProps {
  mask?: string
  error?: string | null
  label?: string | null
  secondLabel?: React.ReactNode
  placeholder?: string | null
  rightDecorator?: React.ReactNode
  onClickRightDeacorator?: () => void
}

const PhoneNumberInput = React.forwardRef(
  (
    {
      id,
      // mask,
      label,
      error,
      value,
      disabled,
      onChange,
      className,
      secondLabel = null,
      // placeholder,
      // rightDecorator,
      // onClickRightDeacorator,
      ...rest
    }: PhoneNumberInputProps & React.InputHTMLAttributes<HTMLInputElement>,

    _ref
  ) => {
    const handleStateChange = (
      _value: unknown,
      _data: unknown,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (event.type === 'change' && !disabled) {
        onChange && onChange(event)
      }
    }

    return (
      <StyledInputWrapper className={className}>
        <StyledLabelsContainer>
          <StyledLabel htmlFor={id}>{label}</StyledLabel>
          {secondLabel}
        </StyledLabelsContainer>
        <PhoneInput
          country={'ru'}
          disabled={disabled}
          // placeholder={placeholder ? placeholder : 'Enter Number'}
          value={String(value)}
          countryCodeEditable={false}
          onChange={handleStateChange}
          onlyCountries={['ru', 'kz', 'ae']}
          buttonClass="change-country-button"
          inputProps={{ ...rest, id, ref: _ref ?? undefined }}
          dropdownStyle={{
            backgroundColor: 'transparent',
          }}
          containerStyle={{
            backgroundColor: 'transparent',
          }}
          inputStyle={{
            width: '100%',
            fontWeight: 500,
            fontSize: '16px',
            transition: '.2s',
            borderRadius: '3px',
            fontFamily: 'Montserrat',
            backgroundColor: 'transparent',
            border: `1px solid ${
              error ? '#ff4444' : 'rgba(255, 255, 255, 0.15)'
            }`,
          }}
          // className={error ? errorInput : phoneInput}
        />
        {/* <div className={rightDecoratorStyles} onClick={onClickRightDeacorator}>
          {rightDecorator}
        </div> */}
        {error && <StyledError>{error}</StyledError>}
      </StyledInputWrapper>
    )
  }
)

const StyledLabelsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`

export default PhoneNumberInput
