import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $userProfile, setUserProfile } from 'stores/userStore'
import { TextLink } from 'styles/typography'
import EmailStepEnterNewEmail from 'components/Settings/email/EmailStepEnterNewEmail'
import Input from 'components/Input'
import Modal from 'components/Modal'
import VerifyByEmail from 'components/Settings/VerifyByEmail'
import VerifyByGoogleAuthenticator from 'components/Settings/VerifyByGoogleAuthenticator'
import VerifyByPhoneNumber from 'components/Settings/VerifyByPhoneNumber'
import api from 'api/api'
import successIcon from 'images/successIcon.png'

enum Step {
  INITIAL = 'INITIAL',
  VERIFY_BY_GA = 'VERIFY BY GA',
  VERIFY_BY_PHONE = 'VERIFY BY PHONE',
  SET_NEW_EMAIL = 'SET NEW EMAIL',
  CONFIRM_NEW_EMAIL = 'CONFIRM NEW EMAIL',
}

const ContactEmail = () => {
  const { t } = useTranslation()
  const user = useStore($userProfile)

  const [step, setStep] = useState<Step>(Step.INITIAL)
  const [email, setEmail] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  const handleCloseModal = () => {
    setStep(Step.INITIAL)
    setEmail('')
  }

  const getContent = () => {
    switch (step) {
      case Step.INITIAL:
        return (
          <Input
            disabled
            id={'email'}
            value={user?.email ?? ''}
            label="Email"
            secondLabel={
              <TextLink onClick={() => setStep(Step.SET_NEW_EMAIL)}>
                {user?.email ? t('common.changeEmail') : t('setting.linkEmail')}
              </TextLink>
            }
            rightDecorator={
              showSuccess && (
                <StyledSuccessIndicator src={successIcon} alt="successIcon" />
              )
            }
          />
        )

      case Step.VERIFY_BY_GA:
        return (
          <Modal isOpen onClose={handleCloseModal}>
            <VerifyByGoogleAuthenticator
              onBack={() => setStep(Step.SET_NEW_EMAIL)}
              submitFunc={async (code: string) =>
                await api.users.putEmailChange({
                  code,
                  email,
                  isTotpRequired: true,
                })
              }
              onSuccess={() => {
                setUserProfile({ ...user, email })
                setShowSuccess(true)
                setTimeout(() => setShowSuccess(false), 3000)
                handleCloseModal()
              }}
              onVerifyByPhone={() => setStep(Step.VERIFY_BY_PHONE)}
            />
          </Modal>
        )

      case Step.VERIFY_BY_PHONE: {
        if (!user?.phoneNumber) return null

        return (
          <Modal isOpen onClose={handleCloseModal}>
            <VerifyByPhoneNumber
              phoneNumber={user.phoneNumber}
              onBack={() => setStep(Step.INITIAL)}
              submitFunc={async (code: string) =>
                await api.users.putEmailChange({
                  code,
                  email,
                  isTotpRequired: false,
                })
              }
              onSuccess={() => {
                setUserProfile({ ...user, email })
                setShowSuccess(true)
                setTimeout(() => setShowSuccess(false), 3000)
                handleCloseModal()
              }}
            />
          </Modal>
        )
      }

      case Step.SET_NEW_EMAIL:
        return (
          <Modal isOpen onClose={handleCloseModal}>
            <EmailStepEnterNewEmail
              initialEmail={email}
              onSubmit={(value) => {
                setEmail(value)
                setStep(Step.CONFIRM_NEW_EMAIL)
              }}
            />
          </Modal>
        )

      case Step.CONFIRM_NEW_EMAIL: {
        const nextStep: Step = user?.isTotpRequired
          ? Step.VERIFY_BY_GA
          : Step.VERIFY_BY_PHONE

        return (
          <Modal isOpen onClose={handleCloseModal}>
            <VerifyByEmail
              email={email}
              onBack={() => setStep(Step.SET_NEW_EMAIL)}
              submitFunc={async (code: string) => {
                await api.code.postVerifyCode({
                  login: email,
                  verificationCode: +code,
                })
              }}
              onEmailChange={() => setStep(Step.SET_NEW_EMAIL)}
              onSuccess={() => setStep(nextStep)}
            />
          </Modal>
        )
      }

      default:
        return null
    }
  }

  return <StyledWrapper>{getContent()}</StyledWrapper>
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledSuccessIndicator = styled.img`
  position: absolute;
  top: 48px;
  right: 16px;
`

export default ContactEmail
