import {
  $config,
  $isUserReady,
  $lobby,
  $lobbyMemberByPosition,
  $match,
  $teamsPowers,
  Country,
  changeLobbyBet,
  changeLobbyCountry,
  changeLobbyMap,
  changeLobbyReady,
  leaveTeam,
} from 'stores/lobbyStore'
import { $userProfile } from 'stores/userStore'
import {
  Header5,
  Header7,
  SideHeader2,
  SideHeader3,
  Text5,
} from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import AvatarWithRang from 'components/AvatarWithRang'
import BetList from 'components/Game/BetList'
import Button from 'components/Button'
import CSUserCard from 'components/PlayerCardMin/CSUserCard'
import LeaveIcon from 'images/LeaveIcon'
import MapList from 'components/Game/MapList'
import PartyMembersIcon from 'images/PartyMembersIcon'
import React, { useMemo } from 'react'
import ServerChoiser from 'components/ServerChoiser/ServerChoiser'
import StartMatchButton from 'components/StartMatchButton'
import WithUserMenu from 'components/ContextMenu/WithUserMenu'

import EmptyCardCS from 'components/PlayerCardMin/EmptyCardCS'
import HostIcon from 'images/HostIcon'
import NickTrimmer from 'components/NickTrimmer'
import startCSGO from 'utils/startCSGo'
import styled, { css } from 'styled-components'
import textCutter from 'utils/textCutter'
import useLobbyCS from 'hooks/useLobbyCS'
import useStartCSGame from 'hooks/useStartCSGame'

const StyledLobbyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledRoot = styled.div`
  position: relative;
`

const StyledWaitListTitle = styled(Header5)`
  opacity: 0.3;
  text-transform: uppercase;
  padding-right: 16px;

  border-color: rgba(255, 255, 255, 0.25);
  border-right-width: 1px;
`

const StyledLeft = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: -webkit-fill-available;
  gap: 16px;
`

const StyledTeams = styled.div`
  position: relative;

  display: flex;

  justify-content: space-between;
  align-items: center;
  gap: 21px;
  margin-top: 14px;
  margin-bottom: 16px;
`

const StyledTeam = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
`

const StyledTeamsInfo = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 21px;
  margin-bottom: 22px;
`

const StyledTeamInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 810px;
`

const StyledRedTeamTitle = styled(SideHeader2)`
  color: #ff4444;
  text-transform: uppercase;
  text-shadow: 0px -1px 13px rgba(255, 65, 65, 0.5);
`

const StyledBlueTeamTitle = styled(SideHeader2)`
  text-transform: uppercase;

  color: #0fa9ff;
  text-shadow: 0px -1px 13px rgba(0, 117, 255, 0.5);
`

const StyledPower = styled(SideHeader2)`
  display: flex;
  gap: 12px;
  align-items: center;
`

const StyledPowerTitle = styled(SideHeader3)`
  text-transform: uppercase;
  opacity: 0.5;
`
interface StyledGameSettinsWrapperProps {
  $isHost: boolean
}

const StyledGameSettinsWrapper = styled.div<StyledGameSettinsWrapperProps>`
  position: relative;
  display: flex;
  gap: 21px;
  height: 435px;
  & div {
    ${({ $isHost }) =>
      !$isHost &&
      css`
        cursor: default !important;
      `}
  }
`

const StyledWaitLisItem = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-right: 5px;
  width: 127px;
  max-width: 127px;
`

const StyledWaitName = styled(Header7)`
  max-width: 130px;
`

const StyledWaitList = styled.div`
  width: -webkit-fill-available;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 12px;

  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(7.5px);

  border-radius: 3px;
`

const StyledModeName = styled.div`
  position: absolute;
  top: -60px;
  width: 100%;
  text-align: center;
  font-family: Nebula;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    90deg,
    rgba(169, 98, 32, 0.2) 0%,
    rgba(140, 140, 140, 0) 20%,
    rgba(117, 123, 135, 0.04) 80%,
    rgba(21, 53, 114, 0.2) 100%
  );
  background-clip: text;
  color: transparent;
  font-size: 284px;
  font-weight: 400;
  letter-spacing: 48.28px;
  line-height: 284px;
  color: transparent;
  -webkit-background-clip: text !important;
  text-shadow:
    0px 2px 0px rgba(255, 255, 255, 0.05),
    0px -2px 0px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  z-index: -1;
`

const StyledPlayHeader = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 14px;
  gap: 17px;
`

const StyledHost = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

const StyledHostTitle = styled(Text5)`
  display: flex;
  gap: 6px;
  align-items: center;
`

const StyledHostName = styled(Header5)`
  max-width: 150px;
`

const StyledPartyMemberLength = styled(Header7)`
  min-width: max-content;
`

const StyledLeaveIcon = styled(LeaveIcon)`
  transform: rotate(180deg);
`

const StyledButton = styled(Button)`
  min-width: 53px !important;
  height: 49px;
  border: 1px solid transparent;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  &:hover {
    border: 1px solid transparent;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
`

const StyledWaitListContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

const CustomGameCSPage: React.FC = () => {
  const { t } = useTranslation()

  const lobby = useStore($lobby)
  const config = useStore($config)
  const currentUser = useStore($userProfile)
  const lobbyMemberByPosition = useStore($lobbyMemberByPosition)
  const isUserReady = useStore($isUserReady)
  const teamsPowers = useStore($teamsPowers)
  const match = useStore($match)

  const { url } = useStartCSGame({ match, lobby })

  const isHost = currentUser?.id === lobby?.hostId
  const host = lobby?.participants?.find(
    (participant) => participant?.user?.id === lobby.hostId
  )
  const isLobbyBlocked = lobby?.status !== 'NEW'

  const isReady = lobby?.participants?.find(
    (participant) => participant.user?.id === currentUser?.id
  )?.isReady

  const { handleLeaveLobby, handlePositionClick } = useLobbyCS({
    match,
    lobby,
    config,
    isReady,
  })

  const status = useMemo(() => {
    switch (lobby?.status) {
      case 'NEW':
        return 'preparing'
      case 'MATCH_PREPARED':
        return 'started'
      default:
        return 'starting'
    }
  }, [lobby])

  return (
    <StyledRoot>
      <StyledModeName>custom</StyledModeName>
      <StyledPlayHeader>
        <Button withCorner disabled={isLobbyBlocked} onClick={handleLeaveLobby}>
          <LeaveIcon />
          <Header5>{t('play.leave')}</Header5>
        </Button>
        <StyledHost>
          <StyledHostName>
            <NickTrimmer>{host?.user?.name}</NickTrimmer>
          </StyledHostName>
          <StyledHostTitle>
            {t('play.host')} <HostIcon />
          </StyledHostTitle>
        </StyledHost>
      </StyledPlayHeader>
      <StyledLobbyContainer>
        <StyledLeft>
          <StyledWaitList>
            <StyledWaitListContent>
              <StyledWaitListTitle>
                {t('play.customGame.lobby')}
              </StyledWaitListTitle>
              {lobbyMemberByPosition?.wait.map((participant) => (
                <WithUserMenu
                  key={participant.user?.id}
                  profile={participant?.user}
                >
                  <StyledWaitLisItem key={participant?.user?.id}>
                    <AvatarWithRang
                      size="xs"
                      avatar={participant?.user?.imgUrl}
                    />
                    <StyledWaitName>
                      <NickTrimmer>
                        {textCutter(participant?.user?.name)}
                      </NickTrimmer>
                    </StyledWaitName>
                  </StyledWaitLisItem>
                </WithUserMenu>
              ))}
            </StyledWaitListContent>
            <StyledButton onClick={leaveTeam} disabled={isLobbyBlocked}>
              <StyledLeaveIcon />
            </StyledButton>
          </StyledWaitList>

          <PartyMembersIcon />
          <StyledPartyMemberLength>
            {lobby?.participants?.length || 0} / 10
          </StyledPartyMemberLength>
        </StyledLeft>
      </StyledLobbyContainer>
      <StyledTeams>
        <StyledTeam>
          {new Array(5).fill('').map((_, index) => {
            if (lobby && lobbyMemberByPosition?.RED[index + 1]) {
              const user = lobbyMemberByPosition?.RED[index + 1]
              return (
                <CSUserCard
                  key={index}
                  lobby={lobby}
                  disabled={isLobbyBlocked}
                  player={{
                    team: 'RED',
                    profile: user,
                    position: index,
                    isReady: Boolean(user.isReady),
                  }}
                />
              )
            }
            return (
              <EmptyCardCS
                team="RED"
                index={index}
                isLobbyBlocked={isLobbyBlocked}
                handlePositionClick={handlePositionClick}
              />
            )
          })}
        </StyledTeam>
        <StyledTeam>
          {new Array(5).fill('').map((_, index) => {
            if (lobby && lobbyMemberByPosition?.BLUE[index + 1]) {
              const user = lobbyMemberByPosition?.BLUE[index + 1]
              return (
                <CSUserCard
                  key={index}
                  lobby={lobby}
                  disabled={isLobbyBlocked}
                  player={{
                    team: 'BLUE',
                    profile: user,
                    position: index,
                    isReady: Boolean(user.isReady),
                  }}
                />
              )
            }
            return (
              <EmptyCardCS
                key={index}
                team="BLUE"
                index={index}
                isLobbyBlocked={isLobbyBlocked}
                handlePositionClick={handlePositionClick}
              />
            )
          })}
        </StyledTeam>
      </StyledTeams>
      <StyledTeamsInfo>
        <StyledTeamInfo>
          <StyledRedTeamTitle>
            {t('play.customGame.teams.red')}
          </StyledRedTeamTitle>
          <StyledPower>
            {teamsPowers?.RED}
            <StyledPowerTitle>
              {t('play.customGame.teams.power')}
            </StyledPowerTitle>
          </StyledPower>
        </StyledTeamInfo>
        <StyledTeamInfo>
          <StyledPower>
            {teamsPowers?.BLUE}
            <StyledPowerTitle>
              {t('play.customGame.teams.power')}
            </StyledPowerTitle>
          </StyledPower>
          <StyledBlueTeamTitle>
            {t('play.customGame.teams.blue')}
          </StyledBlueTeamTitle>
        </StyledTeamInfo>
      </StyledTeamsInfo>
      <StyledGameSettinsWrapper $isHost={currentUser?.id === lobby?.hostId}>
        <BetList
          status={status}
          bets={config?.bets}
          disabled={isLobbyBlocked}
          currentBets={lobby?.bets || []}
          maxAvailableBet={lobby?.maxAvailableBet}
          onChangeBet={isHost ? changeLobbyBet : undefined}
        />
        <MapList
          status={status}
          disabled={isLobbyBlocked}
          mapList={config?.gameMaps || []}
          currentMaps={lobby?.gameMaps || []}
          onChangeGameMap={isHost ? changeLobbyMap : undefined}
        />
        <ServerChoiser
          disabled={isLobbyBlocked}
          currentServer={lobby?.country}
          servers={config?.countries as Country[]}
          onChangeCurrentServer={isHost ? changeLobbyCountry : undefined}
        />
        <StartMatchButton
          url={url}
          lobby={lobby}
          startCSGO={startCSGO}
          isReady={isUserReady}
          onReadyClick={changeLobbyReady}
          isHost={currentUser?.id === lobby?.hostId}
          isHostReady={
            lobby?.participants?.find(
              (participant) => participant?.user?.id === lobby?.hostId
            )?.isReady || currentUser?.id === lobby?.hostId
          }
        />
      </StyledGameSettinsWrapper>
    </StyledRoot>
  )
}

export default CustomGameCSPage
