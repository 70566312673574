import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import FormButton from 'components/FormButton'
import NewPasswordForm, { FormAuthDataProps } from 'components/NewPasswordForm'

interface PasswordStepEnterNewPasswordProps {
  onSubmit: (password: string) => void
  initialPassword: string
}

const PasswordStepEnterNewPassword = ({
  onSubmit,
  initialPassword,
}: PasswordStepEnterNewPasswordProps) => {
  const { t } = useTranslation()

  const form = useForm<FormAuthDataProps>({
    defaultValues: {
      password: initialPassword,
      passwordConfirm: initialPassword,
    },
  })
  const {
    handleSubmit,
    formState: { isValid },
  } = form

  const submitHandler: SubmitHandler<FormAuthDataProps> = ({ password }) =>
    onSubmit(password)

  return (
    <StyledWrapper>
      <StyledForm onSubmit={handleSubmit(submitHandler)}>
        <NewPasswordForm form={form} />

        <StyledButton type="submit" disabled={!isValid}>
          <StyledButtonContent>
            <StyledButtonText>{t('common.changePassword')}</StyledButtonText>
          </StyledButtonContent>
        </StyledButton>
      </StyledForm>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 576px;
  min-height: 200px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 80px 24px 24px 24px;
  background: var(
    --dashboard-small,
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    ),
    rgba(29, 30, 34, 0.5)
  );
  backdrop-filter: blur(7.5px);
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const StyledButton = styled(FormButton)`
  margin-top: 23px;
`

const StyledButtonContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const StyledButtonText = styled.div`
  font-size: 500;
  text-transform: uppercase;
`

export default PasswordStepEnterNewPassword
