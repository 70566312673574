import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header3, Link, Text4 } from 'styles/typography'
import BigLogo from 'images/BigLogo'
import FormButton from 'components/FormButton'
import NewPasswordForm, { FormAuthDataProps } from 'components/NewPasswordForm'
import PhoneNumberInput from 'components/PhoneNumberInput'
import TellUsYourProblem from 'components/TellUsYourProblem'
import WritePhoneCode from 'components/WritePhoneCode'
import api, { apiAuth } from 'api/api'
import formatNumber from 'utils/formatNumber'
import routes from 'router/routes'

const ResetPasswordPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [step, setStep] = useState<1 | 2 | 3>(1)
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string>('')

  const { t } = useTranslation()

  const navigate = useNavigate()

  const form = useForm<FormAuthDataProps>()
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = form

  const number = watch('number')

  const onSubmit: SubmitHandler<FormAuthDataProps> = async ({
    number,
    password,
  }) => {
    if (!isLoading) {
      setIsLoading(true)
      if (step === 1) {
        try {
          await api.code.getSendPhoneCode({
            phoneNumber: formatNumber(number),
          })

          setStep(2)
        } catch (e) {
          console.log(e)
          // showNotification(
          //   'Неверный токен или пользователь уже существует',
          //   'error'
          // )
        }

        setIsLoading(false)

        return
      }

      if (step === 2 && code.length === 4) {
        try {
          setError('')
          await api.code.postVerifyCode({
            login: formatNumber(number),
            verificationCode: Number(code),
          })

          setStep(3)
        } catch (e) {
          setError(t('registation.codeField.wrong'))
        }
      }
      if (step === 3) {
        try {
          await apiAuth.user.postPasswordForget({
            password,
            login: formatNumber(number),
            verificationCode: Number(code),
          })

          navigate(routes.login())
          //   showNotification('Регистрация прошла успешно')
        } catch (e) {
          //   showNotification('Неверный токен', 'error')
        }
      }

      setIsLoading(false)
    }
  }

  const disabled = !isValid || (step === 2 && code.length !== 4)

  //   const handleGoRestore = () => {
  //     navigate(routes.resetPassword())
  //   }

  //   useEffect(() => {
  //     registationToken.set('')
  //   }, [])
  return (
    <StyledWrapper>
      <TellUsYourProblem />
      <StyledContainer onSubmit={handleSubmit(onSubmit)}>
        <BigLogo />
        <StyledTitle>{t('restorePassword.title')}</StyledTitle>

        <StyledForm>
          {step === 1 && (
            <Controller
              name="number"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PhoneNumberInput
                  id={'number'}
                  label={t('auth.numberField.label')}
                  error={errors.number && t('auth.numberField.errors.required')}
                  {...field}
                />
              )}
            />
          )}
          {step === 2 && (
            <WritePhoneCode
              onCodeChange={setCode}
              phoneNumber={number}
              error={error}
              loading={isLoading}
              onPhoneNumberChange={() => setStep(1)}
            />
          )}
          {step === 3 && <NewPasswordForm form={form} />}

          <StyledButton type="submit" isLoading={isLoading} disabled={disabled}>
            <StyledButtonContent>
              <StyledButtonText>{t('registation.next')}</StyledButtonText>
              <StyledButtonStepCounter>
                {t('registation.steps', { end: 3, start: step })}
              </StyledButtonStepCounter>
            </StyledButtonContent>
          </StyledButton>
        </StyledForm>
      </StyledContainer>

      <StyledFooter>
        {t('registation.back')}
        {` `}
        <Link to={routes.login()}>{t('auth.login')}</Link>
      </StyledFooter>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 61px 0;
  width: 528px;
  align-items: center;
`

const StyledTitle = styled(Header3)`
  color: #f2f2f2;

  margin: 32px 0 38px 0;
  opacity: 0.32;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  text-align: center;
`

const StyledFooter = styled(Text4)`
  color: rgba(255, 255, 255, 0.25);
  text-align: center;

  font-weight: 500;
`

const StyledContainer = styled.form`
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: end;
`

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 550px;
  width: 100%;
`

const StyledButton = styled(FormButton)`
  margin-top: 23px;
`

const StyledButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledButtonText = styled.div`
  font-size: 500;
  text-transform: uppercase;
`

const StyledButtonStepCounter = styled.div`
  color: #ffcd29;
`

export default ResetPasswordPage
