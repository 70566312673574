import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TextLink } from 'styles/typography'
import PhoneNumberInput from 'components/PhoneNumberInput'
import formatNumber from 'utils/formatNumber'

interface PhoneStepEnterNewPhoneProps {
  onSave: (phone: string) => void
}

const PhoneStepEnterNewPhone = ({ onSave }: PhoneStepEnterNewPhoneProps) => {
  const { t } = useTranslation()

  const [phone, setPhone] = useState('')

  const handleSave = () => {
    onSave(formatNumber(phone))
  }

  return (
    <StyledWrapper>
      <PhoneNumberInput
        id={'new-phone'}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        label={t('auth.numberField.new')}
        secondLabel={
          <TextLink onClick={handleSave}>{t('common.save')}</TextLink>
        }
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 576px;
  min-height: 200px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 80px 24px 24px 24px;
  background: var(
    --dashboard-small,
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    ),
    rgba(29, 30, 34, 0.5)
  );
  backdrop-filter: blur(7.5px);
`

export default PhoneStepEnterNewPhone
