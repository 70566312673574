import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Header6, Text5 } from 'styles/typography'

const COLORS = ['#FFCD29', '#FFFFFF26']
const ONE_MINUTE_IN_MS = 60000

interface RemainingTimerProps {
  total: number
  currentProgress: number
  size?: number
  delay?: number
  onExpired?: () => void
}

const RemainingTimer = ({
  total,
  size = 48,
  onExpired,
  currentProgress,
  delay = ONE_MINUTE_IN_MS,
}: RemainingTimerProps) => {
  const { t } = useTranslation()

  const [remaining, setRemaining] = useState<number>(currentProgress)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemaining((prev) => Math.max(0, prev - 1))
    }, delay)

    return () => clearInterval(intervalId)
  }, [delay])

  useEffect(() => {
    if (remaining === 0 && onExpired) {
      onExpired()
    }
  }, [remaining, onExpired])

  const data = [
    { value: remaining, name: 'Remaining' },
    { name: 'Used', value: total - remaining },
  ]

  return (
    <StyledTimerContainer size={size}>
      <StyledRemainingTimeContainer>
        <StyledRemainingTimeText>{remaining}</StyledRemainingTimeText>
        <StyledRemainingTimeMin>{t('results.min')}</StyledRemainingTimeMin>
      </StyledRemainingTimeContainer>

      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            innerRadius="90%"
            outerRadius="100%"
            paddingAngle={0}
            stroke="none"
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </StyledTimerContainer>
  )
}

interface StyledTimerContainer {
  size: number
}

const StyledTimerContainer = styled.div<StyledTimerContainer>`
  position: relative;

  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`

const StyledRemainingTimeContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledRemainingTimeText = styled(Header6)`
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 75%;
`

const StyledRemainingTimeMin = styled(Text5)`
  color: rgba(255, 255, 255, 0.15);
  font-weight: 500;
  line-height: 75%;
`

export default RemainingTimer
