import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TextLink } from 'styles/typography'
import WriteGoogleAuthCode from 'components/WriteGoogleAuthCode'

interface VerifyByGoogleAuthenticatorProps {
  onBack: () => void
  submitFunc: (code: string) => Promise<unknown>
  onSuccess: () => void
  onVerifyByPhone: () => void
}

const VerifyByGoogleAuthenticator = ({
  onBack,
  onSuccess,
  submitFunc,
  onVerifyByPhone,
}: VerifyByGoogleAuthenticatorProps) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleChangeCode = useCallback(
    async (value: string) => {
      if (value.length === 6) {
        setLoading(true)

        try {
          await submitFunc(value)
          onSuccess()
        } catch (error) {
          setIsError(true)
        } finally {
          setLoading(false)
        }
      }
    },
    [onSuccess, submitFunc]
  )

  return (
    <StyledWrapper>
      <WriteGoogleAuthCode
        loading={loading}
        onChange={handleChangeCode}
        onBack={onBack}
        error={isError ? t('common.wrongCode') : undefined}
      />

      <StyledTextLink onClick={onVerifyByPhone}>
        {t('setting.verifyByPhone')}
      </StyledTextLink>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 576px;
  min-height: 250px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 64px 24px 24px 24px;
  background: var(
    --dashboard-small,
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    ),
    rgba(29, 30, 34, 0.5)
  );
  backdrop-filter: blur(7.5px);
`

const StyledTextLink = styled(TextLink)`
  margin-top: 24px;
  text-align: center;
`

export default VerifyByGoogleAuthenticator
