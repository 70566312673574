import mime from 'mime-types'

import { DtoAttachment } from 'api/schemas/chatApi'

export const isVideo = (file: DtoAttachment) => {
  const mimeType = mime.lookup(file.extension!)
  return mimeType && mimeType.startsWith('video/')
}

export const isImage = (file: DtoAttachment) => {
  const mimeType = mime.lookup(file.extension!)
  return mimeType && mimeType.startsWith('image/')
}
