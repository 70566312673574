/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DtoChangeEmailRequest {
  code?: string
  email?: string
  isTotpRequired?: boolean
}

export interface DtoChangePasswordRequest {
  code?: string
  isTotpRequired?: boolean
  password?: string
}

export interface DtoChangePhoneRequest {
  code?: string
  isTotpRequired?: boolean
  phone?: string
}

export interface DtoSteamNameHistory {
  createdAt?: string
  id?: string
  name?: string
  userId?: string
}

export interface DtoTotpConfirm {
  code?: string
}

export interface DtoTotpDto {
  codes?: string[]
  url?: string
}

export interface DtoUpdateUserRequest {
  country?: string
}

export interface DtoUser {
  balance?: number
  block?: DtoUserBlock
  country?: string
  createdAt?: string
  email?: string
  externalId?: string
  gameName?: string
  groupId?: string
  id?: string
  imgUrl?: string
  isTotpRequired?: boolean
  lobbyId?: string
  matchId?: string
  matchMakingId?: string
  name?: string
  online?: boolean
  onlineUpdatedAt?: string
  phoneNumber?: string
  role?: string
  setting?: DtoUserSetting
  steamId?: string
  steamNameHistory?: DtoSteamNameHistory[]
}

export interface DtoUserBlock {
  blockedAt?: string
  blockedBy?: string
  description?: string
  reason?: string
}

export interface DtoUserPublic {
  country?: string
  createdAt?: string
  externalId?: string
  id?: string
  imgUrl?: string
  name?: string
  steamId?: string
  steamNameHistory?: DtoSteamNameHistory[]
}

export interface DtoUserSetting {
  applicationVersion?: string
  closeTheTray?: boolean
  eventNotification?: boolean
  gameNotification?: boolean
  interfaceLanguage?: string
  startingAtWindowsStartup?: boolean
  volume?: number
}

export interface DtoUserShort {
  country?: string
  externalId?: string
  id?: string
  imgUrl?: string
  name?: string
  role?: string
  steamId?: string
}

export interface ResponseResponse {
  data?: any
  meta?: ResponseMeta
  pagination?: ResponsePagination
}

export interface ResponseMeta {
  code?: string
  error?: string
  message?: string
  requestId?: string
  traceId?: string
}

export interface ResponsePagination {
  page?: number
  pages?: number
  perPage?: number
}

export type GetSteamGetError = ResponseResponse

export type GetSteamSyncError = ResponseResponse

export type GetUsersError = ResponseResponse

export type PutUsersError = ResponseResponse

export type GetUsers2Error = ResponseResponse

export type PutCredentialsChangeError = ResponseResponse

export type PutEmailChangeError = ResponseResponse

export type PutPhoneChangeError = ResponseResponse

export type GetSearchError = ResponseResponse

export type PutSettingsError = ResponseResponse

export type PostTotpConfirmError = ResponseResponse

export type PostTotpGenerateError = ResponseResponse

export type DeleteUsersError = ResponseResponse

export namespace Accounts {
  /**
   * @description Get auth steam page
   * @tags Users
   * @name GetSteamGet
   * @summary Get auth steam page
   * @request GET:/accounts/steam/get
   */
  export namespace GetSteamGet {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: string
    }
  }

  /**
   * @description Sync steam account
   * @tags Users
   * @name GetSteamSync
   * @summary Sync steam account
   * @request GET:/accounts/steam/sync
   */
  export namespace GetSteamSync {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

export namespace Users {
  /**
   * @description Get information about the currently logged-in user
   * @tags Users
   * @name GetUsers
   * @summary Get self user information
   * @request GET:/users
   */
  export namespace GetUsers {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Get Setting Data */
      withSetting?: boolean
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoUser
    }
  }

  /**
   * @description Update the information of the currently logged-in user
   * @tags Users
   * @name PutUsers
   * @summary Update user information
   * @request PUT:/users
   */
  export namespace PutUsers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoUpdateUserRequest
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Get public information about user
   * @tags Users
   * @name GetUsers2
   * @summary Get user public information
   * @request GET:/users/:id
   * @originalName getUsers
   * @duplicate
   */
  export namespace GetUsers2 {
    export type RequestParams = {
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoUserPublic
    }
  }

  /**
   * @description Change the password of the currently logged-in user
   * @tags Users
   * @name PutCredentialsChange
   * @summary Change user password
   * @request PUT:/users/credentials/change
   */
  export namespace PutCredentialsChange {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoChangePasswordRequest
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Change the email of the currently logged-in user
   * @tags Users
   * @name PutEmailChange
   * @summary Change email
   * @request PUT:/users/email/change
   */
  export namespace PutEmailChange {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoChangeEmailRequest
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Change the phone of the currently logged-in user
   * @tags Users
   * @name PutPhoneChange
   * @summary Change phone
   * @request PUT:/users/phone/change
   */
  export namespace PutPhoneChange {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoChangePhoneRequest
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description Get  short user information by search
   * @tags Users
   * @name GetSearch
   * @summary Get short user information by search
   * @request GET:/users/search
   */
  export namespace GetSearch {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Name for search */
      userSearchName: string
    }
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoUserShort[]
    }
  }

  /**
   * @description Change user setting
   * @tags Users
   * @name PutSettings
   * @summary Change user setting
   * @request PUT:/users/settings
   */
  export namespace PutSettings {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoUserSetting
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description confirm authenticator binding
   * @tags Users
   * @name PostTotpConfirm
   * @summary confirm authenticator binding
   * @request POST:/users/totp/confirm
   */
  export namespace PostTotpConfirm {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = DtoTotpConfirm
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }

  /**
   * @description generate totp url
   * @tags Users
   * @name PostTotpGenerate
   * @summary generate totp url
   * @request POST:/users/totp/generate
   */
  export namespace PostTotpGenerate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse & {
      data?: DtoTotpDto
    }
  }

  /**
   * @description Delete a user by ID
   * @tags Users
   * @name DeleteUsers
   * @summary Delete user
   * @request DELETE:/users/{id}
   */
  export namespace DeleteUsers {
    export type RequestParams = {
      /** User ID */
      id: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {
      /**
       * Access token
       * @default "Bearer <token>"
       */
      Authorization: string
      /** Request id identity */
      'X-Request-Id': string
    }
    export type ResponseBody = ResponseResponse
  }
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '//localhost:8080/f2f/user/api/v1' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { 'Content-Type': type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data)
  }
}

/**
 * @title User api
 * @version 1.0
 * @baseUrl //localhost:8080/f2f/user/api/v1
 * @contact Mikhel Alexander
 *
 * This is user api service.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  accounts = {
    /**
     * @description Get auth steam page
     *
     * @tags Users
     * @name GetSteamGet
     * @summary Get auth steam page
     * @request GET:/accounts/steam/get
     */
    getSteamGet: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: string
        },
        GetSteamGetError
      >({
        path: `/accounts/steam/get`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Sync steam account
     *
     * @tags Users
     * @name GetSteamSync
     * @summary Sync steam account
     * @request GET:/accounts/steam/sync
     */
    getSteamSync: (params: RequestParams = {}) =>
      this.request<ResponseResponse, GetSteamSyncError>({
        path: `/accounts/steam/sync`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  users = {
    /**
     * @description Get information about the currently logged-in user
     *
     * @tags Users
     * @name GetUsers
     * @summary Get self user information
     * @request GET:/users
     */
    getUsers: (
      query?: {
        /** Get Setting Data */
        withSetting?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoUser
        },
        GetUsersError
      >({
        path: `/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update the information of the currently logged-in user
     *
     * @tags Users
     * @name PutUsers
     * @summary Update user information
     * @request PUT:/users
     */
    putUsers: (request: DtoUpdateUserRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutUsersError>({
        path: `/users`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get public information about user
     *
     * @tags Users
     * @name GetUsers2
     * @summary Get user public information
     * @request GET:/users/:id
     * @originalName getUsers
     * @duplicate
     */
    getUsers2: (id: string, params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoUserPublic
        },
        GetUsers2Error
      >({
        path: `/users/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Change the password of the currently logged-in user
     *
     * @tags Users
     * @name PutCredentialsChange
     * @summary Change user password
     * @request PUT:/users/credentials/change
     */
    putCredentialsChange: (request: DtoChangePasswordRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutCredentialsChangeError>({
        path: `/users/credentials/change`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change the email of the currently logged-in user
     *
     * @tags Users
     * @name PutEmailChange
     * @summary Change email
     * @request PUT:/users/email/change
     */
    putEmailChange: (request: DtoChangeEmailRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutEmailChangeError>({
        path: `/users/email/change`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change the phone of the currently logged-in user
     *
     * @tags Users
     * @name PutPhoneChange
     * @summary Change phone
     * @request PUT:/users/phone/change
     */
    putPhoneChange: (request: DtoChangePhoneRequest, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutPhoneChangeError>({
        path: `/users/phone/change`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get  short user information by search
     *
     * @tags Users
     * @name GetSearch
     * @summary Get short user information by search
     * @request GET:/users/search
     */
    getSearch: (
      query: {
        /** Name for search */
        userSearchName: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        ResponseResponse & {
          data?: DtoUserShort[]
        },
        GetSearchError
      >({
        path: `/users/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change user setting
     *
     * @tags Users
     * @name PutSettings
     * @summary Change user setting
     * @request PUT:/users/settings
     */
    putSettings: (request: DtoUserSetting, params: RequestParams = {}) =>
      this.request<ResponseResponse, PutSettingsError>({
        path: `/users/settings`,
        method: 'PUT',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description confirm authenticator binding
     *
     * @tags Users
     * @name PostTotpConfirm
     * @summary confirm authenticator binding
     * @request POST:/users/totp/confirm
     */
    postTotpConfirm: (request: DtoTotpConfirm, params: RequestParams = {}) =>
      this.request<ResponseResponse, PostTotpConfirmError>({
        path: `/users/totp/confirm`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description generate totp url
     *
     * @tags Users
     * @name PostTotpGenerate
     * @summary generate totp url
     * @request POST:/users/totp/generate
     */
    postTotpGenerate: (params: RequestParams = {}) =>
      this.request<
        ResponseResponse & {
          data?: DtoTotpDto
        },
        PostTotpGenerateError
      >({
        path: `/users/totp/generate`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a user by ID
     *
     * @tags Users
     * @name DeleteUsers
     * @summary Delete user
     * @request DELETE:/users/{id}
     */
    deleteUsers: (id: string, params: RequestParams = {}) =>
      this.request<ResponseResponse, DeleteUsersError>({
        path: `/users/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  }
}
