import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header5 } from 'styles/typography'
import Button from 'components/Button'
import Input from 'components/Input'
import isValidEmail from 'utils/email'

interface EmailStepEnterNewEmailProps {
  initialEmail: string
  onSubmit: (email: string) => void
}

const EmailStepEnterNewEmail = ({
  onSubmit,
  initialEmail,
}: EmailStepEnterNewEmailProps) => {
  const { t } = useTranslation()

  const [email, setEmail] = useState(initialEmail)

  const handleSave = () => {
    onSubmit(email)
  }

  return (
    <StyledWrapper>
      <StyledLabel>{t('setting.enterEmail')}</StyledLabel>

      <Input
        id={'new-email'}
        value={email}
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        label="Email"
      />

      <StyledButton onClick={handleSave} disabled={!isValidEmail(email)}>
        <StyledButtonText>{t('common.changeEmail')}</StyledButtonText>
      </StyledButton>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 576px;
  min-height: 200px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 80px 24px 24px 24px;
  background: var(
    --dashboard-small,
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    ),
    rgba(29, 30, 34, 0.5)
  );
  backdrop-filter: blur(7.5px);
`

const StyledLabel = styled(Header5)`
  color: #ffcd29;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  text-transform: uppercase;
`

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  margin-top: 23px;
`

const StyledButtonText = styled.div`
  font-size: 500;
  text-transform: uppercase;
`

export default EmailStepEnterNewEmail
