import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $userProfile, setUserProfile } from 'stores/userStore'
import { TextLink } from 'styles/typography'
import Modal from 'components/Modal'
import PhoneNumberInput from 'components/PhoneNumberInput'
import PhoneStepEnterNewPhone from 'components/Settings/phone/PhoneStepEnterNewPhone'
import VerifyByGoogleAuthenticator from 'components/Settings/VerifyByGoogleAuthenticator'
import VerifyByPhoneNumber from 'components/Settings/VerifyByPhoneNumber'
import api from 'api/api'
import successIcon from 'images/successIcon.png'

enum Step {
  INITIAL = 'INITIAL',
  VERIFY_BY_GA = 'VERIFY BY GA',
  VERIFY_BY_PHONE = 'VERIFY BY PHONE',
  ENTER_NEW_PHONE = 'ENTER NEW PHONE',
  CONFIRM_NEW_PHONE = 'CONFIRM NEW PHONE',
}

const ContactPhoneNumber = () => {
  const { t } = useTranslation()

  const user = useStore($userProfile)

  const [step, setStep] = useState<Step>(Step.INITIAL)
  const [newPhone, setNewPhone] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  if (!user?.phoneNumber) return null

  const handleCloseModal = () => {
    setStep(Step.INITIAL)
    setNewPhone('')
  }

  const getContent = () => {
    switch (step) {
      case Step.INITIAL: {
        return (
          <>
            <PhoneNumberInput
              value={user.phoneNumber}
              label={t('auth.numberField.label')}
              secondLabel={
                <TextLink onClick={() => setStep(Step.ENTER_NEW_PHONE)}>
                  {t('common.changePhone')}
                </TextLink>
              }
              disabled
            />
            {showSuccess && (
              <StyledSuccessIndicator src={successIcon} alt="successIcon" />
            )}
          </>
        )
      }

      case Step.ENTER_NEW_PHONE:
        return (
          <Modal isOpen onClose={handleCloseModal}>
            <PhoneStepEnterNewPhone
              onSave={(phone) => {
                setNewPhone(phone)
                setStep(Step.CONFIRM_NEW_PHONE)
              }}
            />
          </Modal>
        )

      case Step.CONFIRM_NEW_PHONE: {
        if (!newPhone) return null

        const nextStep: Step = user?.isTotpRequired
          ? Step.VERIFY_BY_GA
          : Step.VERIFY_BY_PHONE

        return (
          <Modal isOpen onClose={handleCloseModal}>
            <VerifyByPhoneNumber
              phoneNumber={newPhone}
              onBack={() => setStep(Step.ENTER_NEW_PHONE)}
              submitFunc={async (code: string) => {
                await api.code.postVerifyCode({
                  login: newPhone,
                  verificationCode: +code,
                })
              }}
              onSuccess={() => setStep(nextStep)}
            />
          </Modal>
        )
      }

      case Step.VERIFY_BY_GA:
        return (
          <Modal isOpen onClose={handleCloseModal}>
            <VerifyByGoogleAuthenticator
              onBack={() => setStep(Step.INITIAL)}
              submitFunc={async (code: string) =>
                await api.users.putPhoneChange({
                  code,
                  phone: newPhone,
                  isTotpRequired: true,
                })
              }
              onSuccess={() => {
                setStep(Step.INITIAL)
                setTimeout(() => {
                  setUserProfile({ ...user, phoneNumber: newPhone })
                  setShowSuccess(true)
                })
                setTimeout(() => setShowSuccess(false), 3000)
              }}
              onVerifyByPhone={() => setStep(Step.VERIFY_BY_PHONE)}
            />
          </Modal>
        )

      case Step.VERIFY_BY_PHONE: {
        const phone = user?.phoneNumber

        if (!phone) return null

        return (
          <Modal isOpen onClose={handleCloseModal}>
            <VerifyByPhoneNumber
              phoneNumber={phone}
              onBack={() => setStep(Step.INITIAL)}
              submitFunc={async (code: string) =>
                await api.users.putPhoneChange({
                  code,
                  phone: newPhone,
                  isTotpRequired: false,
                })
              }
              onSuccess={() => {
                setStep(Step.INITIAL)
                setTimeout(() => {
                  setUserProfile({ ...user, phoneNumber: newPhone })
                  setShowSuccess(true)
                })
                setTimeout(() => setShowSuccess(false), 3000)
              }}
            />
          </Modal>
        )
      }

      default:
        return null
    }
  }

  return <StyledWrapper>{getContent()}</StyledWrapper>
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledSuccessIndicator = styled.img`
  position: absolute;
  top: 48px;
  right: 16px;
`

export default ContactPhoneNumber
