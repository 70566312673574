import { GameChoosen } from 'stores/statisticStore'
import { Header2, Header4, Text3, Text4, Text5 } from 'styles/typography'
import { useTranslation } from 'react-i18next'
import CSLogo from 'images/CSStatisticLogo.svg'
import DotaLogo from 'images/DotaLogo.png'
import GameStatsTexture from 'images/GameStatsTexture.svg'
import styled from 'styled-components'

const StyledRoot = styled.div`
  border-radius: 6px;
  height: 300px;
  width: 520px;
  background: url('${GameStatsTexture}'),
    linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)),
    radial-gradient(
      132.18% 186% at 103.64% 204.83%,
      #499dff 0%,
      rgba(41, 127, 255, 0.43) 42.75%,
      rgba(36, 0, 0, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(0, 38, 65, 0) 100%
    );
`
const StyledBack = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0px 6px 6px 0px;
  border: 0.765px solid rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.03);
  height: 100%;
`
const StyledGameHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const StyledGameTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 21px 0px 0px 32px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom-right-radius: 20px;
  gap: 7px;
  width: 220px;
  height: 79px;
`
const StyledGameText = styled(Header4)`
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
`
const StyledMiddle = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-left: 32px;
`
const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 35px 44px 24px 0;
`
const StyledInfoItemCount = styled(Header2)`
  font-weight: 400;
  color: white;
  line-height: 90%;
`
const StyledInfoItemText = styled(Text4)`
  font-weight: 500;
  color: white;
  line-height: 100%;
  opacity: 0.3;
`
const StyledRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px;
  margin-top: 15px;
  margin-left: auto;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 0px;
  width: 123px;
  border-radius: 3px 0px 0px 3px;
`
const StyledRightBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
const StyledRightBlockItemCount = styled(Text3)`
  color: white;
  line-height: 100%;
`
const StyledRightBlockItemText = styled(Text5)`
  line-height: 115.5%;
  color: white;
  opacity: 0.3;
  text-wrap: nowrap;
`
const StyledGameName = styled(Text4)`
  opacity: 0.3;
  line-height: 80%;
  text-wrap: nowrap;
`
const StyledGameImageWrapper = styled.div`
  margin: 0px 19px 0px 0px;
  width: 89px;
  height: auto;
  border-radius: 3px;
  padding: 4px;
`
const StyledGameImage = styled.div<GameName>`
  background-image: ${({ $gameName }) =>
    $gameName === 'CS' ? `url(${CSLogo})` : `url(${DotaLogo})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`

interface GameName {
  $gameName: GameChoosen
}

interface IGameStats {
  gameName: GameChoosen
  page: 'popup' | 'statistic'
  hoursInMode?: string | number
  gamesInTotal?: string | number
  hoursInTotal?: string | number
  matchesInMode?: string | number
}
const GameStats: React.FC<IGameStats> = ({
  gameName,
  hoursInMode,
  hoursInTotal,
  gamesInTotal,
  matchesInMode,
}) => {
  const { t } = useTranslation()

  return (
    <StyledRoot>
      <StyledBack>
        <StyledGameHead>
          <StyledGameTitle>
            <StyledGameText>{t('UserAccountPopup.statistic')}</StyledGameText>
            <StyledGameName>
              {gameName === 'CS' ? 'CS 2' : 'DOTA 2'}
            </StyledGameName>
          </StyledGameTitle>
          <StyledGameImageWrapper>
            <StyledGameImage $gameName={gameName} />
          </StyledGameImageWrapper>
        </StyledGameHead>
        <StyledMiddle>
          <StyledInfoItem>
            <StyledInfoItemCount>{matchesInMode || 0}</StyledInfoItemCount>
            <StyledInfoItemText>
              {t('UserAccountPopup.matchesInMode')}
            </StyledInfoItemText>
          </StyledInfoItem>
          <StyledInfoItem>
            <StyledInfoItemCount>{hoursInMode || 0}</StyledInfoItemCount>
            <StyledInfoItemText>
              {t('UserAccountPopup.hoursInMode')}
            </StyledInfoItemText>
          </StyledInfoItem>
          <StyledRightBlock>
            <StyledRightBlockItem>
              <StyledRightBlockItemCount>
                {gamesInTotal || 0}
              </StyledRightBlockItemCount>
              <StyledRightBlockItemText>
                {t('UserAccountPopup.gamesTotal')}
              </StyledRightBlockItemText>
            </StyledRightBlockItem>
            <StyledRightBlockItem>
              <StyledRightBlockItemCount>
                {hoursInTotal || 0}
              </StyledRightBlockItemCount>
              <StyledRightBlockItemText>
                {t('UserAccountPopup.hoursTotal')}
              </StyledRightBlockItemText>
            </StyledRightBlockItem>
          </StyledRightBlock>
        </StyledMiddle>
      </StyledBack>
    </StyledRoot>
  )
}

export default GameStats
