import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $userProfile } from 'stores/userStore'
import { TextLink } from 'styles/typography'
import Input from 'components/Input'
import Modal from 'components/Modal'
import PasswordStepEnterNewPassword from 'components/Settings/password/PasswordStepEnterNewPassword'
import VerifyByGoogleAuthenticator from 'components/Settings/VerifyByGoogleAuthenticator'
import VerifyByPhoneNumber from 'components/Settings/VerifyByPhoneNumber'
import api from 'api/api'
import successIcon from 'images/successIcon.png'

enum Step {
  INITIAL = 'INITIAL',
  VERIFY_BY_GA = 'VERIFY BY GA',
  VERIFY_BY_PHONE = 'VERIFY BY PHONE',
  SET_NEW_PASSWORD = 'SET NEW PASSWORD',
}

const PasswordChange = () => {
  const { t } = useTranslation()

  const user = useStore($userProfile)

  const [step, setStep] = useState<Step>(Step.INITIAL)
  const [password, setPassword] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  const handleCloseModal = () => {
    setStep(Step.INITIAL)
    setPassword('')
  }

  const getContent = () => {
    switch (step) {
      case Step.INITIAL:
        return (
          <Input
            disabled
            id={'password'}
            value="hidden password"
            label={t('auth.passwordField.label')}
            type="password"
            secondLabel={
              <TextLink onClick={() => setStep(Step.SET_NEW_PASSWORD)}>
                {t('common.changePassword')}
              </TextLink>
            }
            rightDecorator={
              showSuccess && (
                <StyledSuccessIndicator src={successIcon} alt="successIcon" />
              )
            }
          />
        )

      case Step.VERIFY_BY_GA:
        return (
          <Modal isOpen onClose={handleCloseModal}>
            <VerifyByGoogleAuthenticator
              onBack={() => setStep(Step.SET_NEW_PASSWORD)}
              submitFunc={async (code: string) =>
                await api.users.putCredentialsChange({
                  code,
                  password,
                  isTotpRequired: true,
                })
              }
              onSuccess={() => {
                setStep(Step.INITIAL)
                setShowSuccess(true)
                setTimeout(() => setShowSuccess(false), 3000)
              }}
              onVerifyByPhone={() => {
                setStep(Step.VERIFY_BY_PHONE)
              }}
            />
          </Modal>
        )

      case Step.VERIFY_BY_PHONE: {
        if (!user?.phoneNumber) return null

        return (
          <Modal isOpen onClose={handleCloseModal}>
            <VerifyByPhoneNumber
              phoneNumber={user.phoneNumber}
              onBack={() => setStep(Step.SET_NEW_PASSWORD)}
              submitFunc={async (code: string) =>
                await api.users.putCredentialsChange({
                  code,
                  password,
                  isTotpRequired: false,
                })
              }
              onSuccess={() => {
                setStep(Step.INITIAL)
                setShowSuccess(true)
                setTimeout(() => setShowSuccess(false), 3000)
              }}
            />
          </Modal>
        )
      }

      case Step.SET_NEW_PASSWORD:
        return (
          <Modal isOpen onClose={handleCloseModal}>
            <PasswordStepEnterNewPassword
              initialPassword={password}
              onSubmit={(value) => {
                setPassword(value)
                setStep(
                  user?.isTotpRequired
                    ? Step.VERIFY_BY_GA
                    : Step.VERIFY_BY_PHONE
                )
              }}
            />
          </Modal>
        )

      default:
        return null
    }
  }

  return <StyledWrapper>{getContent()}</StyledWrapper>
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledSuccessIndicator = styled.img`
  position: absolute;
  top: 48px;
  right: 16px;
`

export default PasswordChange
