import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header5, Text4, TextLink } from 'styles/typography'
import CodeInput from 'components/CodeInput/CodeInput'
import api from 'api/api'

interface WriteEmailCodeProps {
  email: string
  error: string
  loading: boolean
  onCodeChange: (value: string) => void
  onEmailChange?: () => void
}

const WriteEmailCode = ({
  error,
  email,
  loading,
  onCodeChange,
  onEmailChange,
}: WriteEmailCodeProps) => {
  const { t } = useTranslation()

  const [code, setCode] = useState<string>('')
  const [timer, setTimer] = useState<number>(59)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prev) => Math.max(0, prev - 1))
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    onCodeChange(code)
  }, [code, onCodeChange])

  const handleResendCode = async () => {
    if (!timer) {
      await api.code.getSendEmailCode({
        email,
      })

      setTimer(59)
    }
  }

  return (
    <StyledWrapper>
      <StyledLabel>{t('setting.emailCodeTitle')}</StyledLabel>

      <StyledTooltip>
        {t('setting.emailSent', { email })}{' '}
        {onEmailChange && (
          <TextLink onClick={onEmailChange}>{t('common.changeEmail')}</TextLink>
        )}
      </StyledTooltip>

      <CodeInput
        error={error}
        value={code}
        onChange={setCode}
        disabled={loading}
      />

      <StyledCodeText>
        {timer ? (
          <StyledResend $disabled={true}>
            {t('registation.codeField.resendCodeTimer', { timer })}
          </StyledResend>
        ) : (
          <>
            {t('registation.codeField.dontGetCode')}{' '}
            <StyledResend onClick={handleResendCode}>
              {' '}
              {t('registation.codeField.resendCode')}
            </StyledResend>
          </>
        )}
      </StyledCodeText>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

const StyledTooltip = styled(Text4)`
  width: 100%;
  text-align: center;
`

const StyledLabel = styled(Header5)`
  width: 100%;
  color: #ffcd29;
  text-align: center;
  text-transform: uppercase;
`

const StyledCodeText = styled(Text4)`
  text-align: center;
`

interface StyledResend {
  $disabled?: boolean
}

const StyledResend = styled.span<StyledResend>`
  color: ${({ $disabled }) =>
    $disabled ? ' rgba(255, 255, 255, 0.45);' : '#2cff80'};
  transition: 0.2s;

  cursor: pointer;

  &:hover {
    ${({ $disabled }) =>
      $disabled ? ' rgba(255, 255, 255, 0.45);' : 'rgba(44, 255, 128, 0.3)'};
  }
`

export default WriteEmailCode
