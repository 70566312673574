import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import styled from 'styled-components'

import { $currentJudgeClaim, getJudgeClaimCurrent } from 'stores/judgeStore'
import { EnumCreateJudgeClaimStatus } from 'api/schemas/supportApi'

import JudgeApplicationWizard from 'components/Judge/JudgeApplicationWizard'
import JudgeJoinButton from 'components/Judge/Welcome/JudgeJoinButton'
import JudgeWelcomeSlider from 'components/Judge/Welcome/JudgeWelcomeSlider'
import Loading from 'components/Loading'

const JudgeWelcome = () => {
  const claim = useStore($currentJudgeClaim)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchJudgeClaim = async () => {
      try {
        await getJudgeClaimCurrent()
      } finally {
        setIsLoading(false)
      }
    }

    void fetchJudgeClaim()
  }, [])

  const getContent = () => {
    if (isLoading) {
      return <Loading />
    }

    if (
      claim?.createStatus ===
      EnumCreateJudgeClaimStatus.CreateJudgeClaimStatusCompleted
    ) {
      return <JudgeApplicationWizard claim={claim} />
    }

    return <JudgeJoinButton claim={claim} />
  }

  return (
    <StyledWrapper>
      <JudgeWelcomeSlider />
      {getContent()}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  width: 1630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
`

export default JudgeWelcome
