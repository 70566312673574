import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header5, Text4, TextLink } from 'styles/typography'
import CodeInput from 'components/CodeInput/CodeInput'
import api from 'api/api'
import formatNumber from 'utils/formatNumber'

interface WritePhoneCodeProps {
  phoneNumber: string
  error: string
  loading: boolean
  onCodeChange: (value: string) => void
  onPhoneNumberChange?: () => void
}

const WritePhoneCode = ({
  error,
  loading,
  phoneNumber,
  onCodeChange,
  onPhoneNumberChange,
}: WritePhoneCodeProps) => {
  const { t } = useTranslation()

  const [code, setCode] = useState<string>('')
  const [timer, setTimer] = useState<number>(59)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prev) => Math.max(0, prev - 1))
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    onCodeChange(code)
  }, [code, onCodeChange])

  const handleResendCode = async () => {
    if (!timer) {
      await api.code.getSendPhoneCode({
        phoneNumber: formatNumber(phoneNumber),
      })

      setTimer(59)
    }
  }

  return (
    <StyledWrapper>
      <StyledLabel>{t('registation.codeField.getCode')}</StyledLabel>
      <StyledCodeInfoWrapper>
        <StyledTooltip>
          {t('registation.codeField.lastNumberTooltip')}
        </StyledTooltip>
        <StyledCodeInfoExampleWrapper>
          {t('registation.codeField.forExample')} + 7 (***) ***{' '}
          <StyledCodeInfoExampleBordered>20 04</StyledCodeInfoExampleBordered>
        </StyledCodeInfoExampleWrapper>
        <StyledTooltip>{t('registation.codeField.dontAnswer')}</StyledTooltip>
      </StyledCodeInfoWrapper>

      <StyledTooltip>
        {t('registation.codeField.codeSended', { number: phoneNumber })}{' '}
        {onPhoneNumberChange && (
          <TextLink onClick={onPhoneNumberChange}>
            {t('registation.codeField.changeNumber')}
          </TextLink>
        )}
      </StyledTooltip>

      <CodeInput
        error={error}
        value={code}
        onChange={setCode}
        disabled={loading}
      />

      <StyledCodeText>
        {timer ? (
          <StyledResend $disabled={true}>
            {t('registation.codeField.resendCodeTimer', { timer })}
          </StyledResend>
        ) : (
          <>
            {t('registation.codeField.dontGetCode')}{' '}
            <StyledResend onClick={handleResendCode}>
              {' '}
              {t('registation.codeField.resendCode')}
            </StyledResend>
          </>
        )}
      </StyledCodeText>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

const StyledCodeInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const StyledCodeInfoExampleWrapper = styled(Text4)`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.45);
  gap: 4px;
`

const StyledCodeInfoExampleBordered = styled(Text4)`
  font-weight: 500;
  color: var(--Desktop-Yellow, #ffcd29);
  display: flex;
  padding: 2px 5px;
  border-radius: 3px;
  border: 1px solid rgba(255, 205, 41, 0.5);
`

const StyledTooltip = styled(Text4)`
  width: 100%;
  text-align: center;
`

const StyledLabel = styled(Header5)`
  width: 100%;
  color: #ffcd29;
  text-align: center;
  text-transform: uppercase;
`

const StyledCodeText = styled(Text4)`
  text-align: center;
`

interface StyledResend {
  $disabled?: boolean
}

const StyledResend = styled.span<StyledResend>`
  color: ${({ $disabled }) =>
    $disabled ? ' rgba(255, 255, 255, 0.45);' : '#2cff80'};
  transition: 0.2s;

  cursor: pointer;

  &:hover {
    ${({ $disabled }) =>
      $disabled ? ' rgba(255, 255, 255, 0.45);' : 'rgba(44, 255, 128, 0.3)'};
  }
`

export default WritePhoneCode
