import {
  $createdAppeal,
  AppealStageTypes,
  deleteFiles,
  sendFile,
} from 'stores/chatStores/appealStore'
import { ChangeEvent, useEffect, useState } from 'react'
import { Header6, Text6 } from 'styles/typography'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import ACCEPT_EXTENSIONS from 'consts/acceptExtensions'
import AddFile from 'images/chat/AddFile'
import ImageFileIcon from 'images/judge/ImageFileIcon'
import TrashIcon from 'images/judge/TrashIcon'
import styled from 'styled-components'

const AddFiles: React.FC = () => {
  const { t } = useTranslation()
  const createdAppeal = useStore($createdAppeal)
  const [error, setError] = useState('')

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    let loading = false
    setError('')
    try {
      if (!files || loading) return
      if (files.length > 10) {
        setError(t('chat.appeal.maxFilesLength'))
        return
      }
      loading = true
      for (let i = 0; i < files.length; i++) {
        await sendFile(files[i])
      }
      loading = false
    } catch (e) {
      setError(t('chat.appeal.filesError'))
    }
  }

  const handleDeleteFiles = (id: string) => async () => {
    await deleteFiles(id)
  }

  useEffect(() => {
    $createdAppeal.set({
      ...$createdAppeal.get(),
      nextStep: AppealStageTypes.APPEAL_GENERATED,
    })
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropHandler = async (ev: any) => {
    const files = ev.dataTransfer.files
    for (let i = 0; i < files.length; i++) {
      await sendFile(files[i])
    }
  }

  return (
    <StyledFilesWrapper>
      <StyledFileLabel onDrop={dropHandler}>
        <StyledAddFilesText>
          {t('chat.appeal.addPictures')} <AddFile />
        </StyledAddFilesText>
        <StyledFilesCaption>
          {t('chat.appeal.addPicturesDescr')}
        </StyledFilesCaption>
        <StyledFileInput
          type="file"
          multiple={true}
          onChange={handleFileChange}
          accept={ACCEPT_EXTENSIONS}
        />
      </StyledFileLabel>
      {createdAppeal.downloadFiles?.length ? (
        <StyledBottom>
          <StyledFileNameTextWrapper>
            {createdAppeal.downloadFiles.map((item) => (
              <StyledFileWrapper key={item.name}>
                <ImageFileIcon />
                <StyledFileText>{item.name}</StyledFileText>

                <StyledDeleteFile onClick={handleDeleteFiles(item.id!)} />
              </StyledFileWrapper>
            ))}
            {error && <StyledError>{error}</StyledError>}
          </StyledFileNameTextWrapper>
          {/* <StyledDeleteFile onClick={handleDeleteFiles} /> */}
        </StyledBottom>
      ) : null}
    </StyledFilesWrapper>
  )
}

export default AddFiles

const StyledDeleteFile = styled(TrashIcon)`
  cursor: pointer;
`

const StyledAddFilesText = styled(Header6)`
  display: flex;
  gap: 13px;
`

const StyledFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
const StyledFilesCaption = styled(Text6)`
  line-height: 120%;
  color: white;
  opacity: 0.45;
`
const StyledFileLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 34px;
  padding: 5px 13px;
  border-radius: 3px;
  border: 1px dashed var(--BaseButtonOutline, rgba(255, 255, 255, 0.15));
  background: rgba(255, 255, 255, 0.03);
  cursor: pointer;
  transition: 0.2s;

  & svg path {
    fill: #ffcd29;
    fill-opacity: 1;
  }

  &:hover {
    border-color: #ffcd29;
    background: rgba(238, 157, 62, 0.15);
  }

  &:-webkit-user-drag {
    border-color: #ffcd29;
    background: rgba(238, 157, 62, 0.15);

    & svg {
      fill: #ffcd29;
    }
  }
`

const StyledFileInput = styled.input`
  display: none;
`

const StyledFileText = styled.div`
  max-width: 150px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledFileNameTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  height: 34px;
`
const StyledFileWrapper = styled(Text6)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  height: 34px;
  padding: 0 13px;
  border-radius: 3px;
  border: 1px solid var(--BaseButtonOutline, rgba(255, 255, 255, 0.15));
  background: rgba(255, 255, 255, 0.03);
`

const StyledError = styled(Text6)`
  color: #f44;
`
