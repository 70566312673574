import { Controller, FieldError, UseFormReturn } from 'react-hook-form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header5, Text4, Text6 } from 'styles/typography'
import CloseEyeIcon from 'images/CloseEyeIcon'
import Input from 'components/Input'

export interface FormAuthDataProps {
  code: string
  number: string
  password: string
  passwordConfirm: string
}

interface NewPasswordFormProps {
  form: UseFormReturn<FormAuthDataProps>
}

const NewPasswordForm = ({ form }: NewPasswordFormProps) => {
  const {
    watch,
    control,
    formState: { errors },
  } = form

  const { t } = useTranslation()

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const [isShowPasswordSecond, setIsShowPasswordSecond] =
    useState<boolean>(false)

  const password = watch('password')

  const getPasswordError = (error?: FieldError) => {
    if (!error) {
      return
    }
    switch (error.type) {
      case 'lat':
        return t('registation.passwordField.errors.lat')
      case 'num':
        return t('registation.passwordField.errors.num')
      case 'minLength':
        return t('registation.passwordField.errors.minLength')
      case 'down':
        return t('registation.passwordField.errors.down')
      case 'up':
        return t('registation.passwordField.errors.up')
      case 'symbol':
        return t('registation.passwordField.errors.symbol')
      default:
        return t('registation.passwordField.errors.required')
    }
  }

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }

  const handleShowPasswordSecond = () => {
    setIsShowPasswordSecond(!isShowPasswordSecond)
  }

  return (
    <>
      <StyledLabel>{t('registation.passwordField.label')}</StyledLabel>
      <StyledTooltip>{t('registation.passwordField.tooltip')}</StyledTooltip>
      <Controller
        defaultValue=""
        name="password"
        control={control}
        rules={{
          required: true,
          validate: {
            lat: (v) => /[A-Za-z]+/.test(v),
            // eslint-disable-next-line perfectionist/sort-objects
            up: (v) => /[A-Z]+/.test(v),
            down: (v) => /[a-z]+/.test(v),
            // eslint-disable-next-line perfectionist/sort-objects
            num: (v) => /[1-9]+/.test(v),
            minLength: (v) => v.trim().length >= 8,
            symbol: (v) => /[!@#$%^&*`/\\[\]().,?]+/.test(v),
          },
        }}
        render={({ field }) => (
          <Input
            id={'password'}
            onClickRightDeacorator={handleShowPassword}
            type={isShowPassword ? 'text' : 'password'}
            placeholder={t('registation.passwordField.placeholder') || ''}
            rightDecorator={
              isShowPassword ? <CloseEyeIcon /> : <CloseEyeIcon />
            }
            {...field}
          />
        )}
      />

      <Controller
        defaultValue=""
        control={control}
        name="passwordConfirm"
        rules={{
          required: true,
          validate: { correct: (v) => v === password },
        }}
        render={({ field }) => (
          <Input
            id={'passwordConfirm'}
            onClickRightDeacorator={handleShowPasswordSecond}
            type={isShowPasswordSecond ? 'text' : 'password'}
            placeholder={t('registation.secondPasswordField.placeholder') || ''}
            rightDecorator={
              isShowPasswordSecond ? <CloseEyeIcon /> : <CloseEyeIcon />
            }
            {...field}
          />
        )}
      />

      {(errors.password || errors.passwordConfirm) && (
        <StyledErrorsContainer>
          {errors.password && (
            <StyledError>{getPasswordError(errors.password)}</StyledError>
          )}

          {errors.passwordConfirm && (
            <StyledError>
              {t('registation.secondPasswordField.errors.match')}
            </StyledError>
          )}
        </StyledErrorsContainer>
      )}
    </>
  )
}

const StyledTooltip = styled(Text4)`
  width: 100%;
  text-align: center;
`

const StyledLabel = styled(Header5)`
  color: #ffcd29;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
`

const StyledErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const StyledError = styled(Text6)`
  color: #ff4444;
  font-weight: 500;
  text-align: center;
`

export default NewPasswordForm
