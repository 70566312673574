import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Header6 } from 'styles/typography'
import BackArrow from 'images/BackArrow.svg'
import WritePhoneCode from 'components/WritePhoneCode'
import api from 'api/api'
import formatNumber from 'utils/formatNumber'

interface VerifyByPhoneNumberProps {
  phoneNumber: string
  onBack: () => void
  submitFunc: (code: string) => Promise<unknown>
  onSuccess: () => void
  onPhoneNumberChange?: () => void
}

const VerifyByPhoneNumber = ({
  onBack,
  onSuccess,
  submitFunc,
  phoneNumber,
  onPhoneNumberChange,
}: VerifyByPhoneNumberProps) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    void api.code.getSendPhoneCode({
      phoneNumber: formatNumber(phoneNumber),
    })
  }, [phoneNumber])

  const handleChangeCode = useCallback(
    async (value: string) => {
      if (value.length === 4) {
        setLoading(true)

        try {
          await submitFunc(value)
          onSuccess()
        } catch (error) {
          setIsError(true)
        } finally {
          setLoading(false)
        }
      }
    },
    [onSuccess, submitFunc]
  )

  return (
    <StyledWrapper>
      <WritePhoneCode
        loading={loading}
        phoneNumber={phoneNumber}
        error={isError ? t('registation.codeField.wrong') : ''}
        onCodeChange={handleChangeCode}
        onPhoneNumberChange={onPhoneNumberChange}
      />

      <StyledStep onClick={onBack}>
        <StyledStepText>{t('registation.backButton')}</StyledStepText>
        <StyledStepLogo />
      </StyledStep>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 576px;
  min-height: 250px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 64px 24px 24px 24px;
  background: var(
    --dashboard-small,
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    ),
    rgba(29, 30, 34, 0.5)
  );
  backdrop-filter: blur(7.5px);
`

const StyledStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  width: 100%;
  height: 42px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  margin-top: 46px;
  cursor: pointer;
`

const StyledStepLogo = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${BackArrow});
`

const StyledStepText = styled(Header6)`
  text-transform: uppercase;
`

export default VerifyByPhoneNumber
