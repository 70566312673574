import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $currentAppeal, $isCheatAppeal } from 'stores/chatStores/appealStore'
import { DtoMessage, EnumClaimReportCategory } from 'api/schemas/supportApi'
import { Text4, Text5 } from 'styles/typography'
import { getMatchText } from 'utils/getMatchText'
import { isProd } from 'api/api'
import { useClaimReasonTranslations } from 'components/ClaimsTable/translations'
import { useNavigate } from 'react-router'
import Button from 'components/Button'
import GalleryModal from 'components/GalleryModal'
import JudgeLinkIcon from 'images/chat/JudgeLinkIcon'
import NickTrimmer from 'components/NickTrimmer'
import routes from 'router/routes'

interface SupportMessageInfoProps {
  message?: DtoMessage
}

const SupportMessageInfo = ({ message }: SupportMessageInfoProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentAppeal = useStore($currentAppeal)
  const isCheatAppeal = useStore($isCheatAppeal)

  const [currentFileIndex, setCurrentFileIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const files = message?.attachments ?? []
  const reasonT = useClaimReasonTranslations()

  return (
    <>
      <StyledWrapper>
        <StyledAppealInfoWrapper>
          {currentAppeal?.category && (
            <StyledInfoItem>
              <StyledInfoItemCaption>
                {t('chat.appeal.category')}:
              </StyledInfoItemCaption>
              <StyledInfoItemDescription>
                {t(
                  `chat.appeal.${currentAppeal.category as EnumClaimReportCategory}`
                )}
              </StyledInfoItemDescription>
            </StyledInfoItem>
          )}
          {currentAppeal?.reportedMatch?.gameName && (
            <StyledInfoItem>
              <StyledInfoItemCaption>
                {t('chat.appeal.game')}:
              </StyledInfoItemCaption>
              <StyledInfoItemDescription>
                {currentAppeal.reportedMatch.gameName}
              </StyledInfoItemDescription>
            </StyledInfoItem>
          )}
          {currentAppeal?.reportedMatch && (
            <StyledInfoItem>
              <StyledInfoItemCaption>
                {t('chat.appeal.matchID')}:
              </StyledInfoItemCaption>
              <StyledInfoItemDescription>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {getMatchText(currentAppeal.reportedMatch, true).toUpperCase()}
              </StyledInfoItemDescription>
            </StyledInfoItem>
          )}
          {currentAppeal?.reportedUser && (
            <StyledInfoItem>
              <StyledInfoItemCaption>
                {t('chat.appeal.player')}:
              </StyledInfoItemCaption>
              <StyledInfoItemDescription>
                <NickTrimmer>{currentAppeal?.reportedUser.name}</NickTrimmer>
              </StyledInfoItemDescription>
            </StyledInfoItem>
          )}
          {currentAppeal?.timeCodes && (
            <StyledInfoItem>
              <StyledInfoItemCaption>
                {t('chat.appeal.timeCodes')}:
              </StyledInfoItemCaption>
              <StyledInfoItemDescription>
                {currentAppeal?.timeCodes?.[0]?.[0]} -{' '}
                {currentAppeal?.timeCodes?.[0]?.[1]}
              </StyledInfoItemDescription>
            </StyledInfoItem>
          )}
          {currentAppeal?.reasons && (
            <StyledInfoItem>
              <StyledInfoItemCaption>
                {currentAppeal.reasons.length > 1
                  ? `${t('chat.appeal.reasons')}`
                  : `${t('chat.appeal.reason')}`}
                :
              </StyledInfoItemCaption>
              {currentAppeal.reasons.map((reason, i) => (
                <StyledInfoItemDescription key={i}>
                  {reasonT[reason]}
                </StyledInfoItemDescription>
              ))}
            </StyledInfoItem>
          )}
          {currentAppeal?.description && (
            <StyledInfoItem>
              <StyledInfoItemCaption>
                {t('chat.appeal.yourDescr')}:
              </StyledInfoItemCaption>
              <StyledInfoItemDescriptions>
                {currentAppeal?.description}
              </StyledInfoItemDescriptions>
            </StyledInfoItem>
          )}
          {files.length > 0 && (
            <StyledInfoFiles>
              <StyledInfoItemCaption>
                {t('chat.appeal.additionFiles')}:
              </StyledInfoItemCaption>
              <StyledFilesList>
                {files.map((file, index) => (
                  <StyledFile
                    key={file.id}
                    $src={file.previewUrl}
                    onClick={() => {
                      setCurrentFileIndex(index)
                      setIsModalOpen(true)
                    }}
                  />
                ))}
                {isModalOpen && (
                  <GalleryModal
                    files={files}
                    initialIndex={currentFileIndex}
                    onClose={() => setIsModalOpen(false)}
                  />
                )}
              </StyledFilesList>
            </StyledInfoFiles>
          )}
        </StyledAppealInfoWrapper>
        {message?.text && (
          <StyledAdditionalText>{message?.text}</StyledAdditionalText>
        )}
      </StyledWrapper>
      {isCheatAppeal ? (
        <StyledCheatInfoWrapper>
          <div>
            Ваша заявка направлена на рассмотрение Стражам, чтобы отслеживать
            статус заявки, вы можете перейти в раздел Судейства или нажать на
            кнопку ниже:
          </div>
          <StyledButton
            onClick={() => navigate(routes.spectatingMyTickets())}
            disabled={isProd}
            size="s"
          >
            Перейти <JudgeLinkIcon />
          </StyledButton>
        </StyledCheatInfoWrapper>
      ) : null}
    </>
  )
}

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  stroke: none;
  & > div {
    justify-content: space-between;
    width: 100%;
  }
`

const StyledCheatInfoWrapper = styled(Text4)`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid var(--BaseButtonOutline, rgba(255, 255, 255, 0.15));
  background: rgba(255, 255, 255, 0.03);
  padding: 12px;
`

const StyledAppealInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StyledInfoItem = styled(Text5)`
  display: flex;
  gap: 4px;
`

const StyledInfoItemCaption = styled(Text5)`
  color: #2cff80;
  line-height: 120%;
`

const StyledInfoItemDescriptions = styled.div`
  color: white;
  line-height: 120%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const StyledInfoItemDescription = styled(Text5)`
  color: white;
  line-height: 120%;
  display: flex;
  gap: 6px;
  word-break: break-word;
  margin-left: 5px;
`

const StyledAdditionalText = styled(Text5)`
  margin: 19px 0;
`

const StyledInfoFiles = styled(Text5)`
  display: flex;
  flex-direction: column;
`

const StyledFilesList = styled.div`
  display: flex;
  gap: 16px;
`

interface StyledFileProps {
  $src?: string
}

const StyledFile = styled.div<StyledFileProps>`
  width: 100px;
  height: 60px;
  margin-top: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background-image: ${({ $src }) => `url('${$src || null}')`};
  background-size: cover;
  background-position: center;
  cursor: pointer;
`

export default SupportMessageInfo
