import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  $showQrCodePopup,
  $userProfile,
  toggleQrCodePopup,
} from 'stores/userStore'
import { Header6, Text4 } from 'styles/typography'
import Button from 'components/Button'
import GoogleLogo from 'images/google-logo.webp'
import Modal from 'components/Modal'
import QrCodePopup from 'components/QrCodePopup'
import QuestionCircle from 'images/QuestionCircle'

const GoogleAuthenticator = () => {
  const { t } = useTranslation()
  const user = useStore($userProfile)
  const showQrCodePopup = useStore($showQrCodePopup)

  const isGoogleAuthenticatorConnected = !!user?.isTotpRequired

  const toggleQrPopup = (visible: boolean) => () => {
    toggleQrCodePopup(visible)
  }

  return (
    <Styledwrapper>
      {isGoogleAuthenticatorConnected && (
        <StyledInfoWarning>
          {t('setting.haveAlreadyfactorError')}
          <QuestionCircle />
        </StyledInfoWarning>
      )}
      <StyledButton
        type="button"
        onClick={toggleQrPopup(true)}
        disabled={isGoogleAuthenticatorConnected}
      >
        <StyledButtonText>{t('setting.connectGoogle')}</StyledButtonText>
        <StyledGoogleLogo />
      </StyledButton>

      <Modal isOpen={showQrCodePopup} onClose={toggleQrPopup(false)}>
        <QrCodePopup />
      </Modal>
    </Styledwrapper>
  )
}

const Styledwrapper = styled.div`
  width: 100%;
`

const StyledInfoWarning = styled(Text4)`
  color: #f44;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;
`

const StyledGoogleLogo = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${GoogleLogo});
  background-size: contain;
  position: absolute;
  right: 12px;
  top: 9px;
`

const StyledButton = styled(Button)`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`

const StyledButtonText = styled(Header6)`
  text-transform: uppercase;
`

export default GoogleAuthenticator
