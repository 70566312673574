import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $userConfig, changeUserConfig } from 'stores/userStore'
import { Header3, Text3, Text4 } from 'styles/typography'
import AccountInformation from 'components/Settings/AccountInformation'
import AudioSlider from 'components/Settings/AudioSlider'
import Checkbox from 'components/Checkbox'
import ModalHeader from 'components/Modals/ModalHeader'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Toggle from 'components/Toggle'

type LangOptions = 'en' | 'ru'

const LANGUAGES: LangOptions[] = ['en', 'ru']

const SettingsPage = () => {
  const { t } = useTranslation()

  const config = useStore($userConfig)

  const VOLUME_OPTIONS = [t('setting.on'), t('setting.off')]

  const onSettingChange = (name: string) => async (value: string | boolean) => {
    await changeUserConfig(name, value)
  }

  const onLngChange = async (lng: LangOptions) => {
    await changeUserConfig('interfaceLanguage', lng.toLocaleLowerCase())
  }

  const onVolumeToggle = async (value: string) => {
    await onVolumeChange(value === t('setting.off') ? 0 : 50)
  }

  const onVolumeChange = async (value: number) => {
    await changeUserConfig('volume', value)
  }

  return (
    <ModalWrapper>
      <StyledModalHeader>
        <ModalHeader modalType="settings" />
      </StyledModalHeader>
      {/* <ModalContent> */}
      <StyledRoot>
        <StyledTitleWrapper>
          <StyledTitle>{t('setting.langTitle')}</StyledTitle>
          <Toggle
            items={LANGUAGES}
            padding="3px 19px"
            onChange={onLngChange}
            value={config?.interfaceLanguage as LangOptions}
          />
        </StyledTitleWrapper>
        <SettingsBlock>
          <StyledTitleWrapper>
            <StyledTitle>{t('setting.general')}</StyledTitle>
          </StyledTitleWrapper>
          <StyledCheckboxes>
            <StyledCheckboxWrapper>
              <Checkbox
                isActive={Boolean(config?.closeTheTray)}
                onChange={onSettingChange('closeTheTray')}
              >
                <StyledLabel>{t('setting.trayLabel')}</StyledLabel>
              </Checkbox>
            </StyledCheckboxWrapper>
            <StyledCheckboxWrapper>
              <Checkbox
                isActive={Boolean(config?.startingAtWindowsStartup)}
                onChange={onSettingChange('startingAtWindowsStartup')}
              >
                <StyledLabel>{t('setting.startingLabel')}</StyledLabel>
              </Checkbox>
            </StyledCheckboxWrapper>
            <StyledCheckboxWrapper>
              <Checkbox
                disabled
                isActive={false}
                onChange={onSettingChange('gameNotification')}
              >
                <StyledLabel>{t('setting.gamesNotifications')}</StyledLabel>
              </Checkbox>
              <StyledCheckboxWrapperDescr $visible={true}>
                {t('setting.gamesNotificationsDescr')}
              </StyledCheckboxWrapperDescr>
            </StyledCheckboxWrapper>
            <StyledCheckboxWrapper>
              <Checkbox
                disabled
                isActive={false}
                onChange={onSettingChange('eventNotification')}
              >
                <StyledLabel>{t('setting.eventsNotifications')}</StyledLabel>
              </Checkbox>
              <StyledCheckboxWrapperDescr $visible={true}>
                {t('setting.eventsNotificationsDescr')}
              </StyledCheckboxWrapperDescr>
            </StyledCheckboxWrapper>
          </StyledCheckboxes>
        </SettingsBlock>
        <SettingsBlock>
          <AccountInformation />
        </SettingsBlock>
        <SettingsBlock>
          <StyledTitleWrapper>
            <StyledTitle>{t('setting.audioTitle')}</StyledTitle>
            <Toggle
              padding="3px 19px"
              items={VOLUME_OPTIONS}
              onChange={onVolumeToggle}
              value={config?.volume ? t('setting.on') : t('setting.off')}
            />
          </StyledTitleWrapper>
          <StyledAudioSliderWrapper>
            <AudioSlider
              onVolumeChange={onVolumeChange}
              initialValue={config?.volume || 0}
            />
          </StyledAudioSliderWrapper>
        </SettingsBlock>
      </StyledRoot>
      {/* </ModalContent> */}
    </ModalWrapper>
  )
}

const StyledModalHeader = styled.div`
  padding: 20px 20px 20px 30px;
`

const StyledTitle = styled(Header3)`
  text-transform: uppercase;
  padding-bottom: 8px;
`
const StyledCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const StyledCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
interface LabelDescrProps {
  $visible: boolean
}
const StyledCheckboxWrapperDescr = styled(Text4)<LabelDescrProps>`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
  padding-left: 37px;
  margin-top: 10px;
  line-height: 125%;
  opacity: 0.25;
`

const StyledLabel = styled(Text3)`
  line-height: 100%;
`

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const SettingsBlock = styled.div`
  width: 100%;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.03);
  padding: 24px;
`

const StyledRoot = styled.div`
  padding: 0px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`

const StyledAudioSliderWrapper = styled.div`
  margin-top: 32px;
`

export default SettingsPage
