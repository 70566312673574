import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  $currentWorkClaim,
  getCurrentWorkClaim,
  getNextWorkClaim,
  setReportPopupId,
} from 'stores/judgeStore'
import { Header6 } from 'styles/typography'
import { openModal } from 'stores/appStore'
import ClaimsTableRow from 'components/ClaimsTable/ClaimsTableRow'
import JoinButtonDots from 'images/judge/JoinButtonDots'
import Loading from 'components/Loading'

const MyWorkClaim = () => {
  const { t } = useTranslation()
  const currentWorkClaim = useStore($currentWorkClaim)

  const [isLoading, setIsLoading] = useState(true)
  const [isFetchingNewClaim, setIsFetchingNewClaim] = useState(false)

  useEffect(() => {
    const fetchCurrentWorkClaim = async () => {
      await getCurrentWorkClaim()
      setIsLoading(false)
    }

    void fetchCurrentWorkClaim()
  }, [])

  if (isLoading) {
    return <Loading />
  }

  if (currentWorkClaim) {
    return (
      <>
        <ClaimsTableRow
          claim={currentWorkClaim}
          onClick={() => {
            setReportPopupId(currentWorkClaim.id!)
            openModal('report')
          }}
          onExpired={getCurrentWorkClaim}
          withTimer
        />
      </>
    )
  }

  const handleGetNextClaim = async () => {
    if (isFetchingNewClaim) return

    setIsFetchingNewClaim(true)
    const claim = await getNextWorkClaim()
    setIsFetchingNewClaim(false)

    if (!claim) {
      toast.info(t('judge.judgePage.noClaimsForWork'))
    }
  }

  return (
    <StyledButton onClick={handleGetNextClaim}>
      <JoinButtonDots />
      <StyledButtonText>
        {isFetchingNewClaim
          ? t('common.pleaseWait')
          : t('judge.judgePage.findApplication')}
      </StyledButtonText>
      <JoinButtonDots />
    </StyledButton>
  )
}

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(7.5px);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    border-color: #ffcd29;
  }
`

const StyledButtonText = styled(Header6)`
  color: white;
  text-transform: uppercase;
  margin: 0 40px;
  border-radius: 3px;
`

export default MyWorkClaim
