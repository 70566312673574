import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  $currentClaim,
  $currentWorkClaim,
  $reportPopupId,
  addFileToClaim,
  getMyFinishedClaims,
  removeFileFromClaim,
  verdictClaim,
} from 'stores/judgeStore'
import { DtoClaim } from 'api/schemas/judgeApi'
import {
  Header2,
  Header5,
  Header6,
  Text3,
  Text4,
  Text5,
} from 'styles/typography'
import { closeModal } from 'stores/appStore'
import ACCEPT_EXTENSIONS from 'consts/acceptExtensions'
import Button from 'components/Button'
import CopyWrapper from 'components/CopyWrapper'
import ImageFileIcon from 'images/judge/ImageFileIcon'
import Input from 'components/Input'
import JudgeAbleToVoteTimer from 'components/Judge/Report/JudgeAbleToVoteTimer'
import JudgeIcon from 'images/judge/JudgeIcon'
import PaperClip from 'images/judge/PaperClip'
import TimeCode from 'components/TimeCode'
import TrashIcon from 'images/judge/TrashIcon'

interface JudgeDecisionFormProps {
  claim: DtoClaim
}

const JudgeDecisionForm: React.FC<JudgeDecisionFormProps> = ({ claim }) => {
  const { t } = useTranslation()

  const [isGuiltyConfirm, setIsGuiltyConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isReadyToVote, setIsReadyToVote] = useState(false)

  if (!claim.myDecision) return null

  const timeCodes = claim.myDecision.timeCodes || []
  const attachments = claim.myDecision.attachments || []
  const description = claim.myDecision.description || ''

  const handleConfirm = async () => {
    setIsLoading(true)
    await verdictClaim()
    await getMyFinishedClaims()
    $currentWorkClaim.set(null)
    $reportPopupId.set(null)
    closeModal('report')
  }

  const handleChangeIsGuilty = (isGuilty: boolean) => {
    $currentClaim.set({
      ...claim,
      myDecision: { ...claim.myDecision!, isGuilty },
    })
  }

  const handleGuiltyConfirm = () => {
    handleChangeIsGuilty(true)
    setIsGuiltyConfirm(true)
  }

  const handleUnguiltyConfirm = () => {
    handleChangeIsGuilty(false)
    setIsGuiltyConfirm(true)
  }

  const disabled = isLoading || !description || !isReadyToVote

  const handleDescriptionChange = (value: string) => {
    $currentClaim.set({
      ...claim,
      myDecision: { ...claim.myDecision, description: value },
    })
  }

  const handleTimecodesChange = (value: string[]) => {
    $currentClaim.set({
      ...claim,
      myDecision: { ...claim.myDecision, timeCodes: [value] },
    })
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      await addFileToClaim(claim.id!, file)
      e.target.value = ''
    }
  }

  return (
    <>
      <StyledMainContent>
        <JudgeAbleToVoteTimer onFinish={() => setIsReadyToVote(true)} />
        <StyledResultInput>
          <StyledHeaderWrapper>
            <StyledHeader>{t('judge.reportModal.decision')}</StyledHeader>
            <StyledIdContainer>
              <CopyWrapper textToCopy={claim.externalId!}>
                <StyledDescription>
                  <StyledTicket>
                    {t('judge.reportModal.applicationId')}
                  </StyledTicket>
                  <StyledTicketID>{claim.externalId}</StyledTicketID>
                </StyledDescription>
              </CopyWrapper>
            </StyledIdContainer>
          </StyledHeaderWrapper>
          <StyledInputWrapper>
            <StyledInputContainer>
              <StyledInputCount>
                <InputCountContainer>
                  <StyledGreenDescription>01</StyledGreenDescription>
                  <StyledGreenDescription>/</StyledGreenDescription>
                  <StyledGrayDescription>03</StyledGrayDescription>
                </InputCountContainer>
              </StyledInputCount>
              <StyledInputInfo>
                <InputTitle>
                  {t('judge.reportModal.explain')}
                  <StyledGreenDescription>*</StyledGreenDescription>
                </InputTitle>
                <InputContainer>
                  <StyledInput
                    value={description}
                    onChange={(e) => handleDescriptionChange(e.target.value)}
                    placeholder={t('judge.reportModal.startWrite')}
                    disabled={isGuiltyConfirm}
                  />
                  <InputRequiredDescription>
                    {description ? (
                      <StyledGreenDescription>
                        {t('judge.reportModal.requiredFill')}
                      </StyledGreenDescription>
                    ) : (
                      <StyledRedDescription>
                        {t('judge.reportModal.requiredFill')}
                      </StyledRedDescription>
                    )}
                  </InputRequiredDescription>
                </InputContainer>
              </StyledInputInfo>
            </StyledInputContainer>
            <StyledInputContainer>
              <StyledInputCount>
                <InputCountContainer>
                  <StyledGreenDescription>02</StyledGreenDescription>
                  <StyledGreenDescription>/</StyledGreenDescription>
                  <StyledGrayDescription>03</StyledGrayDescription>
                </InputCountContainer>
              </StyledInputCount>
              <StyledInputInfo>
                <InputTitle>{t('judge.reportModal.setTimeCode')}</InputTitle>
                <InputContainer>
                  <StyledTimeCodeWrapper $isGuiltyConfirm={isGuiltyConfirm}>
                    <StyledTimeCode
                      values={timeCodes ? timeCodes[0] : []}
                      onChange={handleTimecodesChange}
                    />
                  </StyledTimeCodeWrapper>
                </InputContainer>
              </StyledInputInfo>
            </StyledInputContainer>
            <StyledInputContainer>
              <StyledInputCount>
                <InputCountContainer>
                  <StyledGreenDescription>03</StyledGreenDescription>
                  <StyledGreenDescription>/</StyledGreenDescription>
                  <StyledGrayDescription>03</StyledGrayDescription>
                </InputCountContainer>
              </StyledInputCount>
              <StyledInputInfo>
                <InputContainer>
                  <StyledFileLabel>
                    {attachments.length !== 3 ? (
                      <FileAreaDescription>
                        {t('judge.reportModal.addFiles')}
                        <StyledClipButton />
                      </FileAreaDescription>
                    ) : (
                      <StyledUploadFilesHint>
                        {t('judge.reportModal.maxUploadFiles')}
                      </StyledUploadFilesHint>
                    )}
                    {attachments.length !== 3 && (
                      <>
                        <AcceptedFilesHint>
                          <StyledGrayDescription>
                            {t('judge.reportModal.maxUploadFilesHint')}
                          </StyledGrayDescription>
                        </AcceptedFilesHint>
                        <StyledFileInput
                          disabled={isGuiltyConfirm}
                          type="file"
                          accept={ACCEPT_EXTENSIONS}
                          onChange={handleFileChange}
                        />
                      </>
                    )}
                  </StyledFileLabel>
                  <AttachedFilesWrapper>
                    {attachments.map((file) => (
                      <File key={file.id}>
                        <FileInfoWrapper>
                          <ImageFileIcon />
                          {file.name}
                        </FileInfoWrapper>
                        <FileDeleteLogo
                          disabled={isGuiltyConfirm}
                          onClick={(e) => {
                            e.preventDefault()
                            removeFileFromClaim(claim.id!, file)
                          }}
                        >
                          <TrashIcon />
                        </FileDeleteLogo>
                      </File>
                    ))}
                  </AttachedFilesWrapper>
                </InputContainer>
              </StyledInputInfo>
            </StyledInputContainer>
          </StyledInputWrapper>
        </StyledResultInput>
      </StyledMainContent>

      <StyledFooter>
        <ConfirmUnguilty
          onClick={handleUnguiltyConfirm}
          disabled={(isGuiltyConfirm && claim.myDecision?.isGuilty) || disabled}
        >
          <JudgeGuiltyIcon />
          <StyledGuiltyDescription>
            {t('judge.reportModal.notGuilty')}
          </StyledGuiltyDescription>
        </ConfirmUnguilty>
        {!isGuiltyConfirm && (
          <ConfirmGuilty onClick={handleGuiltyConfirm} disabled={disabled}>
            <JudgeGuiltyIcon />
            <StyledGuiltyDescription>
              {t('judge.reportModal.guilty')}
            </StyledGuiltyDescription>
          </ConfirmGuilty>
        )}
        {isGuiltyConfirm && (
          <ConfirmWrapper>
            <StyledConfirmDescription>
              {t('judge.reportModal.areYouSure')}
            </StyledConfirmDescription>
            <ButtonsWrapper>
              <ButtonCancel
                onClick={() => setIsGuiltyConfirm(false)}
                disabled={disabled}
              >
                <ButtonTitle>{t('judge.reportModal.cancel')}</ButtonTitle>
              </ButtonCancel>
              <ButtonConfirm
                onClick={() => handleConfirm()}
                disabled={disabled}
              >
                <ButtonTitle>{t('judge.reportModal.yes')}</ButtonTitle>
              </ButtonConfirm>
            </ButtonsWrapper>
          </ConfirmWrapper>
        )}
      </StyledFooter>
    </>
  )
}

const StyledMainContent = styled.div`
  display: flex;
  width: 100%;
  height: 554px;
  margin: 0px 0px 32px 0px;
  gap: 15px;
`

const StyledResultInput = styled.div`
  width: 100%;
  border: 1px solid #ffffff26;
  border-radius: 3px;
`

const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 37px 50px 0px 32px;
`

const StyledHeader = styled(Header2)`
  line-height: 34px;
  font-weight: 400;
`
const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const StyledIdContainer = styled.div`
  margin: 3px 0px 0px 0px;
  padding: 5px 25px;
  display: flex;
  width: auto;
  align-items: center;
  height: 31px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`
const StyledTicket = styled(Text4)`
  border-right: 1px solid #ffffff26;
  font-weight: 500;
  padding: 0px 10px 0px 0px;
`
const StyledTicketID = styled(Text4)`
  font-weight: 500;
  padding: 0px 0px 0px 10px;
`
const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px 0px 32px;
  gap: 13px;
`
const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border: 1px solid #ffffff26;
  border-radius: 3px;
  background: #ffffff08;
`
const StyledInputCount = styled(Text4)`
  padding: 17px 29px 0px 23px;
  width: 134px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const InputCountContainer = styled(Text3)`
  gap: 7px;
  display: flex;
`
const StyledGreenDescription = styled.div`
  color: #2cff80;
`
const StyledRedDescription = styled.div`
  color: #ff4444;
`
const StyledGrayDescription = styled.div`
  color: #ffffff26;
`
const StyledInputInfo = styled.div`
  padding: 24px 24px 24px 0px;
  width: 100%;
  height: 100%;
`
const InputTitle = styled(Text4)`
  padding: 0px 0px 24px 0px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  gap: 5px;
`
const InputContainer = styled.div``
const InputRequiredDescription = styled(Text5)`
  padding: 6px 0px 0px 0px;
  text-align: right;
  font-weight: 500;
`
const StyledInput = styled(Input)`
  input::placeholder {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  display: flex;
  height: 34px;
`
const StyledTimeCode = styled(TimeCode)`
  border: none;
  display: flex;
  input {
    border: none;
    padding: 0;
    font-size: 16px;
    &:focus-within {
      height: 24px;
    }
    &:first-child {
      border-right: 1px solid #ffffff26;
      &:focus-within {
        border-right: 1px solid #ffcd29;
      }
    }
    &:last-child {
      &:focus-within {
        border-radius: 0px 3px 3px 0px;
      }
    }
  }
`

const StyledFooter = styled.div`
  border-top: 1px solid #ffffff26;
  height: 65px;
  padding: 20px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ConfirmUnguilty = styled(Button)`
  padding: 0px 0px 0px 11px;
  width: 308px;
  height: 42px;
  fill: #ffcd29;
  &:disabled,
  &:active {
    fill: white;
  }
`
const ConfirmGuilty = styled(Button)`
  padding: 0px 0px 0px 11px;
  height: 42px;
  width: 1072px;
  fill: #ffcd29;
  &:disabled,
  &:active {
    fill: white;
  }
`
const StyledGuiltyDescription = styled(Header5)`
  margin: 0px 0px 0px 8px;
`
const JudgeGuiltyIcon = styled(JudgeIcon)``
const ConfirmWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 32px;
`
const StyledConfirmDescription = styled(Text4)`
  font-size: 16px;
  font-weight: 500;
  color: #ff4444;
`
const ButtonsWrapper = styled.div`
  display: flex;
  height: 32px;
  gap: 16px;
`
const ButtonCancel = styled(Button)`
  justify-content: center;
  display: flex;
  border: none;
  background: none;
  &:hover,
  &:active {
    border: none;
    background: none;
  }
  &:active {
    color: #ff4444;
  }
`
const ButtonConfirm = styled(Button)`
  justify-content: center;
  width: 100px;
`
const ButtonTitle = styled(Header6)`
  font-size: 14px;
`

interface StyledTimeCodeWrapperProps {
  $isGuiltyConfirm: boolean
}
const StyledTimeCodeWrapper = styled.div<StyledTimeCodeWrapperProps>`
  pointer-events: ${(props) => (props.$isGuiltyConfirm ? 'none' : 'all')};
`
const StyledFileInput = styled.input`
  display: none;
`
const StyledFileLabel = styled.label`
  padding: 10px 31px 10px 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 34px;
  border-radius: 3px;
  border: 1px dashed rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.03);
  cursor: pointer;
  transition: 0.2s;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;

  &:hover {
    border-color: #ffcd29;
    background: rgba(238, 157, 62, 0.15);
  }
`
const FileAreaDescription = styled(Text5)`
  gap: 13px;
  display: flex;
  align-items: center;
`
const StyledClipButton = styled(PaperClip)`
  width: 24px;
  height: 24px;
`
const AcceptedFilesHint = styled(Text5)`
  color: #fff;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`
const AttachedFilesWrapper = styled.div`
  padding: 8px 0px 0px 0px;
  gap: 6px;
  display: flex;
  flex-direction: row;
`
const File = styled.div`
  padding: 7px 7px 7px 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 210px;
  height: 34px;
  border: 1px solid #ffffff26;
  border-radius: 3px;
  transition: 0.3s;
  &:hover {
    border-color: #ffcd29;
    background: rgba(238, 157, 62, 0.15);
  }
`
const FileInfoWrapper = styled(Text5)`
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 9px;
`
const StyledUploadFilesHint = styled(Text5)`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const FileDeleteLogo = styled(Button)`
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: none;
  border: none;
  transition: 0.3s;
  svg {
    stroke: none;
  }
  &:hover {
    border: none;
    background: none;
    svg {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.5;
  }
`

export default JudgeDecisionForm
