import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { $userProfile } from 'stores/userStore'
import { Header3, Header6, Text4 } from 'styles/typography'
import { setIsShowExitPopup } from 'stores/appStore'
import Button from 'components/Button'
import ContactEmail from 'components/Settings/email/ContactEmail'
import ContactPhoneNumber from 'components/Settings/phone/ContactPhoneNumber'
import CopyWrapper from 'components/CopyWrapper'
import GoogleAuthenticator from 'components/Settings/GoogleAuthenticator'
import PasswordChange from 'components/Settings/password/PasswordChange'
import QuestionCircle from 'images/QuestionCircle'

const AccountInformation = () => {
  const { t } = useTranslation()
  const user = useStore($userProfile)

  const toggleLogoutPopup = (visible: boolean) => async () => {
    await setIsShowExitPopup(visible)
  }

  return (
    <>
      <StyledTitleWrapper>
        <StyledTitle>{t('setting.personal')}</StyledTitle>
        <StyledTitle>
          {t('setting.profileId')}:
          <CopyWrapper textToCopy={user?.externalId || ''}>
            <StyledId> #{user?.externalId || ''}</StyledId>
          </CopyWrapper>
        </StyledTitle>
      </StyledTitleWrapper>
      <StyledAccountWrapper>
        <LeftColumnContainer>
          <ContactPhoneNumber />
          <PasswordChange />
          <GoogleAuthenticator />
        </LeftColumnContainer>
        <StyledContainer>
          <ContactEmail />
          <StyledButtonWithWarningWrapper>
            <StyledInfoWarning>
              {t('setting.youLoseProfileInformation')}
              <QuestionCircle />
            </StyledInfoWarning>
            <StyledButton type="button" onClick={toggleLogoutPopup(true)}>
              <StyledButtonText>{t('setting.logout')}</StyledButtonText>
            </StyledButton>
          </StyledButtonWithWarningWrapper>
        </StyledContainer>
      </StyledAccountWrapper>
    </>
  )
}

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const StyledTitle = styled(Header3)`
  text-transform: uppercase;
  padding-bottom: 8px;
`

const StyledId = styled.span`
  color: #ffcd29;
`

const StyledAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 120px;
`

const StyledContainer = styled.form`
  width: 756px;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: space-between;
`

const LeftColumnContainer = styled(StyledContainer)`
  gap: 32px;
`

const StyledButton = styled(Button)`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`

const StyledButtonText = styled(Header6)`
  text-transform: uppercase;
`

const StyledInfoWarning = styled(Text4)`
  color: #f44;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;
`

const StyledButtonWithWarningWrapper = styled.div`
  width: 100%;
`

export default AccountInformation
