import { Header2, Header3, Text3, Text4, Text5 } from 'styles/typography'
import { useState } from 'react'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import JudgeFilesTexture from 'images/judge/JudgeFilesTexture.svg'
import UploadFilesIcon from 'images/judge/UploadFilesIcon.svg'

import { $currentClaim } from 'stores/judgeStore'
import { downloadAttachments } from 'utils/download'
import { useClaimReasonTranslations } from 'components/ClaimsTable/translations'
import Button from 'components/Button'
import CopyWrapper from 'components/CopyWrapper'
import GalleryModal from 'components/GalleryModal'
import WithTooltip from 'components/Tooltip/TableHeader/WithTooltip'

const TicketInfo = () => {
  const { t } = useTranslation()

  const claimReasonLabels = useClaimReasonTranslations()
  const claim = useStore($currentClaim)

  const [currentFileIndex, setCurrentFileIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!claim) return null

  const {
    category,
    description,
    reasons = [],
    reportedMatch,
    externalId = '',
    attachments = [],
  } = claim

  const formattedReasons = reasons.map((r) => claimReasonLabels[r]).join(', ')

  if (!reportedMatch) return null

  const handleDownloadAttachments = () => {
    downloadAttachments(attachments)
  }

  return (
    <>
      <StyledInfo>
        <StyledDescrpitionContainer>
          <StyledTicketInfo>
            <StyledInfoTitle>
              {t('judge.reportModal.information')}
            </StyledInfoTitle>
            <StyledIdContainer>
              <CopyWrapper textToCopy={externalId}>
                <StyledDescription>
                  <StyledTicket>
                    {t('judge.reportModal.applicationId')}
                  </StyledTicket>
                  <StyledTicketID>{externalId}</StyledTicketID>
                </StyledDescription>
              </CopyWrapper>
            </StyledIdContainer>
          </StyledTicketInfo>
          <StyledDescription>
            <StyledTicketReason>
              <MatchDetails>
                <StyledMatchDetails>
                  <InfoContainer>
                    <SetTicketDescription>
                      {t(`chat.appeal.${category!}`)}
                    </SetTicketDescription>
                    <TicketDescription>
                      <StyledGrayDescription>
                        {t('judge.reportModal.category')}
                      </StyledGrayDescription>
                    </TicketDescription>
                  </InfoContainer>
                  <InfoContainer>
                    <SetTicketDescription>
                      {reportedMatch.externalId}
                    </SetTicketDescription>
                    <MarginTicketInfo>
                      <StyledGrayDescription>
                        {t('judge.reportModal.matchId')}
                      </StyledGrayDescription>
                    </MarginTicketInfo>
                  </InfoContainer>
                  <InfoContainer>
                    <SetTicketDescription>
                      {claim.timeCodes?.[0].join(' - ') || '-'}
                    </SetTicketDescription>
                    <MarginTicketInfo>
                      <StyledGrayDescription>
                        {t('judge.reportModal.timing')}
                      </StyledGrayDescription>
                    </MarginTicketInfo>
                  </InfoContainer>
                </StyledMatchDetails>
                <InfoContainer>
                  <WithTooltip text={formattedReasons}>
                    <StyledClaimReasons>{formattedReasons}</StyledClaimReasons>
                  </WithTooltip>
                  <MarginTicketInfo>
                    <StyledGrayDescription>
                      {t('judge.reportModal.reason')}
                    </StyledGrayDescription>
                  </MarginTicketInfo>
                </InfoContainer>
              </MatchDetails>
            </StyledTicketReason>
            <StyledReason>
              <StyledReasonText>
                <StyledGrayDescription>
                  {t('judge.reportModal.descrpition')}
                </StyledGrayDescription>
              </StyledReasonText>
              <StyledReasonContainer>
                <StyledScrollbar>
                  <StyledReasonDescription>
                    {description}
                  </StyledReasonDescription>
                </StyledScrollbar>
              </StyledReasonContainer>
            </StyledReason>
          </StyledDescription>
        </StyledDescrpitionContainer>
        <StyledAttachedFiles>
          <AttachedFiles>
            {t('judge.reportModal.attachedFiles')}
            <DownloadButton
              onClick={handleDownloadAttachments}
              disabled={!attachments || attachments.length === 0}
            >
              <img src={UploadFilesIcon} />
            </DownloadButton>
          </AttachedFiles>
          {attachments && (
            <FilesContainer>
              <ImageContainer $attachmentCount={attachments.length}>
                {attachments.map(({ id, name, previewUrl }, index) => (
                  <Image
                    key={id}
                    src={previewUrl}
                    alt={name}
                    onClick={() => {
                      setCurrentFileIndex(index)
                      setIsModalOpen(true)
                    }}
                  />
                ))}

                {isModalOpen && (
                  <GalleryModal
                    files={attachments}
                    initialIndex={currentFileIndex}
                    onClose={() => setIsModalOpen(false)}
                  />
                )}
              </ImageContainer>
              <ContentCount>
                <Uploadfilescount>{attachments.length}</Uploadfilescount>
                <StyledGrayDescription>
                  {t('judge.reportModal.countFiles')}
                </StyledGrayDescription>
              </ContentCount>
            </FilesContainer>
          )}
        </StyledAttachedFiles>
      </StyledInfo>
    </>
  )
}

// Define the styled component for the container
const ImageContainer = styled.div<{ $attachmentCount: number }>`
  display: grid;
  width: 100%;
  height: 100%;

  ${({ $attachmentCount }) => {
    switch ($attachmentCount) {
      case 1:
        // One image - specific width and height, centered
        return `
          grid-template-columns: 400px;
          grid-template-rows: 176px;
          gap: 0;

          img {
            width: 100%;
          }
        `
      case 2:
        // Two images - side by side with specific width, height, and gap
        return `
          grid-template-columns: 176px 176px;
          grid-template-rows: 176px;
          gap: 24px;

          img {
            width: 100%;
          }
        `
      case 3:
        // Three images - first image on the left, two smaller ones stacked on the right
        return `
          grid-template-columns: 176px 200px;
          grid-template-rows: 84px 84px;
          gap: 10px 24px;

          & > :nth-child(1) {
            width: 176px;
            height: 176px;
            grid-column: 1 / 2;
            grid-row: 1 / 3; /* Span two rows to occupy full height */
          }

          & > :nth-child(2) {
            width: 200px;
            height: 84px;
            grid-column: 2 / 3;
            grid-row: 1;
          }

          & > :nth-child(3) {
            width: 200px;
            height: 84px;
            grid-column: 2 / 3;
            grid-row: 2;
          }
        `
      // Fallback for more than three images (optional)
      default:
        return `
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-auto-rows: minmax(100px, auto);
      `
    }
  }}
`

// Define the styled component for images
const Image = styled.img`
  height: 100%;
  object-fit: cover;
  border-radius: 4.98px;
  border: 1.66px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
  pointer-events: auto;
`

const StyledInfo = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  border: 1px solid #ffffff26;
  width: 100%;
  height: 263px;
  background: url('${JudgeFilesTexture}'),
    linear-gradient(0deg, rgba(29, 30, 34, 0.5), rgba(29, 30, 34, 0.5)),
    linear-gradient(
      180deg,
      rgba(114, 132, 180, 0.1) 0%,
      rgba(59, 79, 131, 0) 100%
    );
`

const StyledDescrpitionContainer = styled.div`
  display: flex;
  height: 100%;
  width: 56%;
  flex-direction: column;
`
const StyledDescription = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const StyledReason = styled.div`
  padding: 5px 24px 26px 24px;
`
const StyledReasonText = styled(Text5)`
  padding: 0px 0px 3px 0px;
  font-weight: 500;
`
const StyledReasonContainer = styled.div`
  padding: 3px;
  border-radius: 3px;
  border: 1px solid #ffffff26;
  height: 130px;
  width: 365px;
`
const StyledReasonDescription = styled(Text5)`
  font-weight: 500;
  padding: 7px 10px 0px 10px;
`
const StyledScrollbar = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + -3px);
  height: 100%;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    margin: 4px 0 4px;
    border-radius: 1px;
    opacity: 0.1;
    background: rgba(217, 217, 217, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 1px;
  }
`
const StyledTicketInfo = styled.div`
  display: flex;
  padding: 16px 24px 0px 22px;
  height: 85px;
  width: 100%;
  justify-content: space-between;
`
const StyledTicketReason = styled.div`
  border: 1px solid #ffffff26;
  border-bottom: none;
  border-left: none;
  border-radius: 0px 25px 0px 3px;
  width: 430px;
  height: 178px;
`
const StyledInfoTitle = styled(Header3)`
  font-weight: 400;
`
const StyledIdContainer = styled.div`
  margin: 3px 0px 0px 0px;
  padding: 5px 25px 5px 25px;
  display: flex;
  width: auto;
  align-items: center;
  height: 31px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`
const StyledTicket = styled(Text4)`
  border-right: 1px solid #ffffff26;
  font-weight: 500;
  padding: 0px 10px 0px 0px;
`
const StyledTicketID = styled(Text4)`
  font-weight: 500;
  padding: 0px 0px 0px 10px;
`
const MatchDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 425px;
  padding: 20px;
  gap: 24px;
`
const StyledMatchDetails = styled.div`
  display: flex;
  gap: 24px;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const SetTicketDescription = styled(Text3)`
  &:not(nth-child(2)) {
    line-height: 31px;
    margin: 0px 0px 7px 0px;
  }
`
const TicketDescription = styled(Text4)`
  display: flex;
  height: 20px;
  align-items: center;
`
const MarginTicketInfo = styled(Text4)`
  display: flex;
  height: 20px;
  align-items: center;
`
const StyledGrayDescription = styled.div`
  opacity: 0.3;
`
const DownloadButton = styled(Button)`
  display: flex;
  border: 1px solid #ffffff26;
  width: 47px;
  height: 47px;
  border-radius: 3px;
  background-color: #2a2d35;
  justify-content: center;
  align-items: center;
`
const Uploadfilescount = styled(Header2)`
  font-weight: 400;
  line-height: 37px;
`
const StyledAttachedFiles = styled.div`
  padding: 20px;
  display: flex;
  border-left: 1px solid #ffffff26;
  height: 100%;
  width: 44%;
  flex-direction: column;
`
const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
`
const AttachedFiles = styled(Text3)`
  display: flex;
  justify-content: space-between;
`

const ContentCount = styled.div`
  line-height: 20px;
  white-space: nowrap;
  text-align: right;
  display: flex;
  flex-direction: column;
`

const StyledClaimReasons = styled(Text3)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default TicketInfo
