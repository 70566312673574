import {
  $userPopupMapData,
  $userStatisticPageMapData,
  GameChoosen,
} from 'stores/statisticStore'
import { Header4 } from 'styles/typography'
import {
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { styled } from 'styled-components'
import { useStore } from '@nanostores/react'
import { useTranslation } from 'react-i18next'
import MapTexture from 'images/MapTexture.svg'

const StyledMapWrapper = styled.div`
  width: 232px;
  height: 300px;
  backdrop-filter: blur(7.5px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: url('${MapTexture}'),
    linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)),
    linear-gradient(180deg, rgba(114, 132, 180, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
`
const StyledMapTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
  padding-left: 20px;
`
const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
const StyledMapName = styled(Header4)`
  text-transform: uppercase;
  font-size: 16px;
  color: white;
`
const StyledMapDescr = styled.div`
  color: white;
  opacity: 0.3;
`
interface IMapIcon {
  $url?: string
}
const StyledMapIcon = styled.div<IMapIcon>`
  width: 46px;
  height: 46px;
  background-image: ${({ $url }) => `url('${$url || null}')`};
  background-size: contain;
  background-position: center;
  margin-right: 17px;
`
const StyledMapChart = styled.div`
  width: 186px;
  height: 200px;
  margin: 0 auto;
  margin-top: 10px;

  & svg {
    overflow: visible;
  }
`
const StyledTooltip = styled.div`
  display: flex;
  gap: 3px;
  padding: 3px 4px;
  border-radius: 0px 2px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7.5px);
`
const StyledTooltipText = styled.p`
  color: #2cff80;
  font-size: 8px;
  font-weight: 500;
  font-family: Montserrat;

  & > span {
    color: white;
  }
`
interface IMap {
  gameChoosen: GameChoosen
  page: 'userProfile' | 'statistic'
}
const MapStatistic: React.FC<IMap> = ({ page }) => {
  const { t } = useTranslation()
  const mapsList =
    page === 'userProfile'
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (useStore($userPopupMapData) as any[])
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (useStore($userStatisticPageMapData) as any[])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customTick = ({ y, x, payload }: any) => {
    const url = mapsList.filter((map) => map.name === payload.value)[0].imgUrl
    return (
      <g className="recharts-layer recharts-polar-angle-axis-tick">
        <svg
          width="30"
          height="30"
          y={y * 1.15 - 30}
          x={x * 1.15 - 29}
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <image href={url} id="image0" width="100%" height="100%" />
        </svg>
      </g>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomTooltip = ({ active, payload }: any) => {
    if (active) {
      return (
        <StyledTooltip className="custom-tooltip">
          <StyledTooltipText>
            {`${payload?.[0]?.value} `}
            <span>matches</span>
          </StyledTooltipText>
        </StyledTooltip>
      )
    }

    return null
  }
  return (
    <StyledMapWrapper>
      <StyledMapTop>
        <StyledText>
          <StyledMapName>
            {mapsList?.[0].gameCount > 0
              ? mapsList?.[0]?.name || ''
              : t('play.statistic.bestMap')}
          </StyledMapName>
          <StyledMapDescr>
            {mapsList?.[0].gameCount > 0 ? t('play.statistic.bestMap') : ''}
          </StyledMapDescr>
        </StyledText>
        <StyledMapIcon $url={mapsList?.[0]?.imgUrl || ''} />
      </StyledMapTop>
      <StyledMapChart>
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart cy="50%" cx="50%" data={mapsList} outerRadius="80%">
            <radialGradient id="exampleGradient">
              <stop offset="0%" stopColor="rgba(44,255,128,0.38)" />
              <stop offset="90%" stopColor="rgba(44,255,128,1)" />
            </radialGradient>
            <PolarGrid stroke="rgb(147 167 190 / 20%)" />
            <PolarAngleAxis dataKey="name" tick={customTick} />
            <Tooltip content={<CustomTooltip />} />
            <Radar
              name="matches"
              stroke="#2CFF80"
              fillOpacity={0.6}
              dataKey="gameCount"
              // fill="rgb(44, 255, 128)"
              fill="url(#exampleGradient)"
            />
          </RadarChart>
        </ResponsiveContainer>
      </StyledMapChart>
    </StyledMapWrapper>
  )
}

export default MapStatistic
